import React from "react";
import { CSSProperties } from "react";

import { ImageManifest, ImageManifestItem } from "../types/api";

type DynamicImgProps = {
  manifest: ImageManifest;
  fallback?: string;

  // Alternative text if image cannot be loaded.
  alt?: string;

  // Custom media query generator.
  // By default, we query by max-width and max-height based on image dimensions.
  mq?: (i: ImageManifestItem) => string | undefined;

  style?: CSSProperties;
  className?: string;

  onClick?: () => void;
};

export default (props: DynamicImgProps) => {
  const [useFallback, setUseFallback] = React.useState<boolean>(false);

  const onError = () => {
    if (props.fallback !== undefined && props.fallback !== "") {
      setUseFallback(true);
    }
  };

  // Sort images by size.
  // Make a copy since we're getting a read-only object from the cart store.
  const allItems: ImageManifestItem[] = Array.from(props.manifest.all ?? []);
  allItems.sort((a, b) => a.width - b.width);

  const genMQ = props.mq
    ? props.mq
    : (i: ImageManifestItem) => {
        return `(max-width: ${i.width}px) and (max-height: ${i.height}px)`;
      };

  return (
    <picture>
      {!useFallback &&
        allItems.map((a: ImageManifestItem, idx: number) => {
          // Don't set media query for the largest item so on large screens we
          // default to it.
          const media = idx < allItems.length - 1 ? genMQ(a) : undefined;
          return (
            <source key={idx} srcSet={a.url} type={a.type} media={media} />
          );
        })}
      <img
        src={useFallback ? props.fallback : props.manifest.default.url}
        alt={props.alt}
        className={props.className}
        style={props.style}
        onClick={props.onClick}
        onError={onError}
      />
    </picture>
  );
};
