import { FC, useEffect } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter,
  Navigate,
  Route,
  useLocation,
  Routes,
  useParams,
} from "react-router-dom";

import { GetLogInState } from "./utils/Auth";
import Contact from "./pages/Contact";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import ClaimPoints from "./pages/ClaimPoints";
import ForgotPassword from "./pages/ForgotPassword";
import Landing from "./pages/Landing";
import LoginPage from "./pages/Login";
import NotFound from "./pages/NotFound";
import {
  DEFAULT_RESTAURANT_SLUG,
  // RESTAURANT_SLUGS,
  TRestaurantSlug,
} from "./constants/o2";
import OrderMenu from "./pages/OrderMenu";
import PasswordReset from "./pages/PasswordReset";
import PointHistoryPage from "./pages/PointHistoryPage";
import CustomerSettingsPage from "./pages/CustomerSettingsPage";
import Signup from "./pages/Signup";
import TrackOrder from "./pages/TrackOrder";
import LargeVolumeHome from "./pages/LargeVolumeHome";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-182323559-1");
}

const RedirectWithSearch = ({
  replaceRegExp,
  toPath,
}: {
  replaceRegExp: RegExp;
  toPath: string;
}) => {
  const location = useLocation();
  const newPath = location.pathname.replace(replaceRegExp, toPath);
  return <Navigate to={newPath + location.search} replace />;
};

const RedirectToSlug = ({ slug }: { slug: string }) => {
  const location = useLocation();
  return (
    <Navigate
      to={`/${slug}${location.pathname}${location.search}`}
      state={location.state}
      replace
    />
  );
};

const restaurantRoutes = [
  {
    basePath: "menu",
    element: <OrderMenu fulfillment_type="IN_STORE" />,
  },
  {
    basePath: "group",
    element: <LargeVolumeHome />,
  },
  {
    basePath: "group/delivery",
    element: <OrderMenu fulfillment_type="LARGE_VOLUME_DELIVERY" />,
  },
  {
    basePath: "group/pickup",
    element: <OrderMenu fulfillment_type="LARGE_VOLUME_PICKUP" />,
  },
  {
    basePath: "campaign/:groupMenuSlug",
    element: <OrderMenu fulfillment_type="GROUP" />,
  },
  {
    basePath: "orders/:orderID",
    element: <TrackOrder />,
  },
  {
    basePath: "checkout_success",
    element: <CheckoutSuccess fulfillment_type="IN_STORE" />,
  },
  {
    basePath: "campaign/:groupMenuSlug/checkout_success",
    element: <CheckoutSuccess fulfillment_type="GROUP" />,
  },
  {
    basePath: "group/delivery/checkout_success",
    element: <CheckoutSuccess fulfillment_type="LARGE_VOLUME_DELIVERY" />,
  },
  {
    basePath: "group/pickup/checkout_success",
    element: <CheckoutSuccess fulfillment_type="LARGE_VOLUME_PICKUP" />,
  },
  {
    basePath: "checkout_cancel",
    element: (
      <RedirectWithSearch replaceRegExp={/checkout_cancel/} toPath="menu" />
    ),
  },
  {
    basePath: "shipping/checkout_cancel",
    element: (
      <RedirectWithSearch
        replaceRegExp={/shipping\/checkout_cancel/}
        toPath="shipping"
      />
    ),
  },
  {
    basePath: "campaign/:groupMenuSlug/checkout_cancel",
    element: <RedirectWithSearch replaceRegExp={/checkout_cancel/} toPath="" />,
  },
  {
    basePath: "group/delivery/checkout_cancel",
    element: (
      <RedirectWithSearch
        replaceRegExp={/group\/delivery\/checkout_cancel/}
        toPath="group/delivery"
      />
    ),
  },
  {
    basePath: "group/pickup/checkout_cancel",
    element: (
      <RedirectWithSearch
        replaceRegExp={/group\/pickup\/checkout_cancel/}
        toPath="group/pickup"
      />
    ),
  },
  {
    basePath: "claim/:creditID",
    isPrivate: true,
    element: <ClaimPoints />,
  },
  {
    basePath: "login",
    element: <LoginPage />,
  },
  {
    basePath: "signup",
    element: <Signup />,
  },
  {
    basePath: "forgot_password",
    element: <ForgotPassword />,
  },
  {
    basePath: "point_history",
    isPrivate: true,
    element: <PointHistoryPage />,
  },
  {
    basePath: "settings",
    isPrivate: true,
    element: <CustomerSettingsPage />,
  },
];

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const loggedIn = GetLogInState() === "LOGGED_IN";
  const { restaurantSlug } = useParams<{ restaurantSlug: TRestaurantSlug }>();

  if (!loggedIn) {
    return (
      <Navigate to={`/${restaurantSlug}/login`} state={{ from: location }} />
    );
  }

  return children;
};

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.pageview(location.pathname);
    } else {
      console.log("PageView Event", location.pathname);
    }
  }, [location]);

  // const restaurantSlugRegex = RESTAURANT_SLUGS.join("|");

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/contact" element={<Contact />} />
      {restaurantRoutes.map(({ basePath }) => (
        <Route
          key={basePath}
          path={basePath}
          element={<RedirectToSlug slug={DEFAULT_RESTAURANT_SLUG} />}
        />
      ))}
      <Route path=":restaurantSlug">
        <Route index element={<Navigate to="menu" replace />} />
        {restaurantRoutes.map(({ basePath, element, isPrivate }) => (
          <Route
            key={basePath}
            path={basePath}
            element={
              isPrivate ? <PrivateRoute>{element}</PrivateRoute> : element
            }
          />
        ))}
      </Route>
      <Route path="/healthz" element={<div>OK</div>} />
      <Route path="/pwr/:tokenID" element={<PasswordReset />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const App: FC = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;
