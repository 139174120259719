import React from "react";
import { Button, Result } from "antd";

import Page from "../layouts/Page";

export default () => (
  <Page>
    <Result
      status="404"
      title="404"
      subTitle="Sorry, this page does not exist!"
      extra={
        <Button
          type="primary"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go to Homepage
        </Button>
      }
    />
  </Page>
);
