import React from "react";

import { Flex, InputNumber, Radio } from "antd";

import { Tip } from "../types/api";

export type TipBoxProps = {
  defaultTipPercentages?: ReadonlyArray<number>;
  onChange: (t: Tip) => void;
};

export default (props: TipBoxProps) => {
  const [tip, setTip] = React.useState<Tip>({});
  const [showCustom, setShowCustom] = React.useState<boolean>(false);

  const onChange = (e: any) => {
    const selected = e.target.value;
    if (selected === -1) {
      setShowCustom(true);
      setTip({});
    } else {
      setShowCustom(false);
      setTip({ percentage: selected });
    }
  };

  const onCustomChange = (value: any) => {
    if (typeof value === "number") {
      setTip({ fixed_amount: value * 100 });
    } else {
      setTip({});
    }
  };

  React.useEffect(() => {
    props.onChange(tip);
  }, [tip]);

  let tipPercentages: Array<number> = [5, 10];
  if (props.defaultTipPercentages != null) {
    // Fitler out zero since we always have that case as "No Tip".
    tipPercentages = [...props.defaultTipPercentages].filter((p) => p > 0);
  }
  tipPercentages.sort((a, b) => a - b);

  return (
    <Flex vertical align="flex-start" justify="center" style={{ gap: 8 }}>
      <Radio.Group
        defaultValue={0}
        buttonStyle="solid"
        size="large"
        onChange={onChange}
      >
        <Radio.Button value={0}>No Tip</Radio.Button>
        {tipPercentages.map((pct: number) => (
          <Radio.Button key={pct} value={pct}>{`${pct}%`}</Radio.Button>
        ))}
        <Radio.Button value={-1}>Custom</Radio.Button>
      </Radio.Group>
      {showCustom ? (
        <InputNumber
          formatter={(value) => `$ ${value}`}
          min={0}
          size="large"
          onChange={onCustomChange}
          style={{ width: "100%" }}
        />
      ) : (
        <div />
      )}
    </Flex>
  );
};
