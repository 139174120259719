import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Col, Divider, Result, Row, Spin, Statistic } from "antd";
import {
  BookOutlined,
  LogoutOutlined,
  ReloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { fetchT, API_URL } from "../utils/Util";
import { CustomerInfo } from "../types/api";
import { useQuery } from "../hooks/useQuery";

export type CustomerInfoCardProps = {
  customer: CustomerInfo | null;
  err: Error | null;
  refresh: () => void;
  logout: () => void;
};

export default (props: CustomerInfoCardProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const refreshButton: React.ReactElement = (
    <Button block size="large" onClick={props.refresh}>
      <ReloadOutlined /> Refresh / 更新
    </Button>
  );

  const logoutButton: React.ReactElement = (
    <Button type="primary" block size="large" onClick={props.logout}>
      <LogoutOutlined /> Logout / 登出
    </Button>
  );

  let content: React.ReactElement = <Spin />;
  if (props.err) {
    content = (
      <Result
        status="warning"
        title="Sorry, we can't fetch your information right now."
        extra={
          <Row gutter={[16, 12]}>
            <Col span={24}>{refreshButton}</Col>
            <Col span={24}>{logoutButton}</Col>
          </Row>
        }
      />
    );
  } else if (props.customer) {
    content = (
      <Row gutter={[16, 12]}>
        <Col span={24}>
          <Statistic
            title="Name / 名字"
            value={props.customer.name}
            valueStyle={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        </Col>
        <Col span={24}>
          <Statistic title="Points / 點數" value={props.customer.points} />
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            block
            size="large"
            onClick={() =>
              navigate({ pathname: "/point_history", search: query.toString() })
            }
          >
            <BookOutlined /> Point History / 點數紀錄
          </Button>
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col span={24}>
          <Button
            block
            size="large"
            onClick={() =>
              navigate({ pathname: "/settings", search: query.toString() })
            }
          >
            <SettingOutlined /> Settings / 設定
          </Button>
        </Col>
        <Col span={24}>{refreshButton}</Col>
        <Col span={24}>{logoutButton}</Col>
      </Row>
    );
  }

  return <div style={{ width: "min(90vw, 500px)" }}>{content}</div>;
};
