import React from "react";
import { createRoot } from "react-dom/client";
import "./css/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css"; // Import the reset stylesheet
import { ThemeConfig } from "antd/lib";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://9a977cacf19442cb904983fe906892c2@o1261310.ingest.sentry.io/4504748312100864",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.001,
    denyUrls: [/^chrome-extension:\/\//, /^safari-(web-)?extension:\/\//],
  });
}
//BROWN: '#2D211F'
//YELLOW: '#DEC623'
const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: "#DEC623",
    colorText: "#2D211F",
    colorPrimaryText: "#DEC623",
    lineWidth: 3,
    colorBorderBg: "transparent",
  },
  components: {
    Layout: {
      headerHeight: 100,
      headerBg: "#2D211F",
      headerPadding: "0 4rem",
    },
    Button: {
      colorPrimary: "#DEC623",
      primaryColor: "#2D211F",
      colorBorder: "#DEC623",
      colorText: "#2D211F",
      colorBgContainer: "transparent",
      colorTextLightSolid: "#2D211F",
      colorBgContainerDisabled: "#DEC62370",
      borderColorDisabled: "transparent",
    },
    FloatButton: {
      colorPrimary: "#DEC623",
      colorBorder: "#DEC623",
      colorText: "#2D211F",
      colorBgContainer: "transparent",
      colorTextLightSolid: "#2D211F",
    },
    Menu: {
      activeBarBorderWidth: 0,
    },
    Anchor: {
      linkPaddingInlineStart: 32,
    },
    Select: {
      colorBorder: "#DEC623",
    },
    Tag: {
      defaultBg: "#DEC623",
      defaultColor: "#2D211F",
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Form: {
      itemMarginBottom: 16,
    },
  },
};

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <ConfigProvider theme={themeConfig}>
        <App />
      </ConfigProvider>
    </React.StrictMode>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
