import React from "react";

import { Badge, Card, Flex, Tag, Grid, Divider } from "antd";
import { GiftOutlined } from "@ant-design/icons";

import DynamicImg from "./DynamicImg";
import { ImageManifest } from "../types/api";
import { convertPrice } from "../utils/Util";
import Meta from "antd/es/card/Meta";
import Text from "antd/es/typography/Text";
import NoPic from "../img/No_Pic.png";
const { useBreakpoint } = Grid;

type MenuItemButtonProps = {
  pos_key: string | undefined;
  name: string;
  imgManifest?: ImageManifest;
  onClick: () => void;

  price: number;
  points?: number;

  soldOut?: boolean;
};

export default (props: MenuItemButtonProps) => {
  const screens = useBreakpoint();
  const label = props.pos_key;
  // const englishName = restString.match(/[a-zA-Z\s]+/g)?.[0].trim();
  // const chineseName = restString.match(/[\u4e00-\u9fa5]+/g)?.[0].trim();

  const name: React.ReactElement = screens.sm ? (
    <Flex
      vertical
      align="flex-start"
      justify="center"
      style={{ width: "100%" }}
    >
      <Tag bordered={false}>{label}</Tag>
      <Text
        style={{
          width: "100%",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {props.name}
      </Text>
    </Flex>
  ) : (
    <Flex align="center">
      <Tag bordered={false}>{label}</Tag>
      <Text
        style={{
          width: "100%",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {props.name}
      </Text>
    </Flex>
  );

  const price: React.ReactElement = (
    <Flex align="center" gap={4}>
      ${convertPrice(props.price)}
      <Divider type="vertical" />
      {props.points && (
        <div>
          <GiftOutlined />
          &nbsp;{props.points} points
        </div>
      )}
    </Flex>
  );

  let thumbnail: React.ReactElement | undefined = undefined;
  if (props.imgManifest) {
    thumbnail = (
      <DynamicImg
        manifest={props.imgManifest}
        onClick={props.onClick}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: screens.sm ? undefined : "6px 0 0 6px",
        }}
      />
    );
  } else {
    thumbnail = (
      <picture>
        <img
          src={NoPic}
          alt="No Image Available"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: screens.sm ? undefined : "6px 0 0 6px",
          }}
        />
      </picture>
    );
  }
  if (props.soldOut) {
    thumbnail = (
      <Badge.Ribbon color="red" text="Sold Out">
        {thumbnail}
      </Badge.Ribbon>
    );
  }

  return (
    <Card
      hoverable
      onClick={props.onClick}
      cover={screens.sm ? thumbnail : null}
      styles={{
        cover: { maxHeight: "150px", display: "flex" },
        body: {
          display: "flex",
          flexDirection: screens.sm ? "column" : "row",
          padding: screens.sm ? "1rem" : 0,
          justifyContent: "flex-start",
        },
      }}
    >
      {!screens.sm && (
        <div style={{ flex: "0 0 100px", maxHeight: "150px" }}>{thumbnail}</div>
      )}
      <Meta
        title={name}
        description={price}
        style={{ padding: screens.sm ? undefined : "1rem" }}
      />
    </Card>
  );
};
