import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  LoadingOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Alert, Badge, Button, Popover, Space, Spin, Typography } from "antd";

import AnnouncementsBanner from "../components/AnnouncementsBanner";
import {
  GetBasicAuth,
  IsEmployeeUser,
  GetLogInState,
  LogOut,
} from "../utils/Auth";
import Cart, { countPointsInCart } from "../components/Cart";
import InRestaurantBanner from "../components/InRestaurantBanner";
import LargeVolumePickupRules from "../components/LargeVolumePickupRules";
import CustomerInfoCard from "../components/CustomerInfoCard";
import CampaignOrderBanner from "../components/CampaignOrderBanner";
import { useQuery } from "../hooks/useQuery";
import WaitTimeFloater from "../components/WaitTimeFloater";
import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import Page from "../layouts/Page";
import { CustomerInfo, GetMenuResponse } from "../types/api";
import { IsPaidLineItem, CartScope } from "../types/ui";
import { FulfillmentType } from "../types/model";
import { API_URL, fetchT, getFetch, assertNever } from "../utils/Util";
import { LOGO_PATH_OVERRIDE_QUERY_PARAM } from "../constants/constants";
import { useCartStore } from "../stores/CartStore";
import { MenuScroll } from "../components/MenuScroll";

import CustomBanner from "../components/CustomBanner";

const { Title } = Typography;

function isInRestaurantOrder(query: URLSearchParams): boolean {
  return (
    query.get("table_id") != null &&
    (query.get("table_name") != null || query.get("table_name_v2") != null)
  );
}

type OrderMenuProps = {
  fulfillment_type: FulfillmentType;
};

const OrderMenu: React.FC<OrderMenuProps> = (props) => {
  const { restaurantSlug, groupMenuSlug } = useParams<{
    restaurantSlug: TRestaurantSlug;
    groupMenuSlug?: string;
  }>();

  const navigate = useNavigate();
  const location = useLocation();

  const query: URLSearchParams = useQuery();

  // Override the logo link so it always points back to the table order view.
  useEffect(() => {
    if (
      isInRestaurantOrder(query) &&
      query.get(LOGO_PATH_OVERRIDE_QUERY_PARAM) == null
    ) {
      query.append(LOGO_PATH_OVERRIDE_QUERY_PARAM, location.pathname);
      navigate(
        {
          search: query.toString(),
        },
        { replace: true },
      );
    }
  }, [query, location, navigate]);

  const cartScope: CartScope = {
    restaurantSlug: restaurantSlug!, // Using non-null assertion as restaurantSlug should be defined at this point
    groupMenuSlug,
    fulfillmentType: props.fulfillment_type,
  };

  const cart = useCartStore((state) => state.getCartByScope(cartScope));
  const addLineItem = useCartStore((state) => state.addLineItem);
  const removeAllRedeemedItems = useCartStore(
    (state) => state.removeAllRedeemedItems,
  );

  const [showCart, setShowCart] = useState(false);

  // For logged in customer.
  const [customer, setCustomer] = useState<CustomerInfo | null>(null);
  const [customerErr, setCustomerErr] = useState<Error | null>(null);

  const logout = () => {
    removeAllRedeemedItems();
    LogOut();
    window.location.reload();
  };

  const updateCustomer = () => {
    const auth = GetBasicAuth();
    if (auth === null) {
      setCustomerErr(new Error("not logged in"));
      return;
    }

    fetchT<CustomerInfo>({
      method: "GET",
      url: `${API_URL}/v1/customer/r/${RESTAURANT_IDS[restaurantSlug!]}/info`,
      auth: auth,
      handleResponse: setCustomer,
      handleError: setCustomerErr,
    });
  };
  useEffect(updateCustomer, [restaurantSlug]);

  const [menu, setMenu] = useState<GetMenuResponse | null>(null);

  useEffect(() => {
    if (restaurantSlug) {
      let url = `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/menu`;
      switch (props.fulfillment_type) {
        case "GROUP":
        case "CAMPAIGN_BATCH_DELIVERY":
          if (groupMenuSlug) {
            url = `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/group_menu/${groupMenuSlug}`;
          }
          break;
        case "SHIP":
          url = `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/ship/menu`;
          break;
        case "IN_STORE":
          url = `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/menu?menu_type=dine_in`;
          break;
        case "LARGE_VOLUME_DELIVERY":
          url = `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/menu?menu_type=large_volume`;
          break;
        case "LARGE_VOLUME_PICKUP":
          url = `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/menu?menu_type=large_volume`;
          break;
        default:
          assertNever(props.fulfillment_type);
      }

      getFetch<GetMenuResponse>(url, setMenu);
    }
  }, [props.fulfillment_type, groupMenuSlug, restaurantSlug]);

  let numItems = 0;
  let subtotal = 0;
  for (const lineItem of Object.values(cart.lineItems)) {
    numItems += lineItem.quantity;
    if (IsPaidLineItem(lineItem)) {
      subtotal += lineItem.price * lineItem.quantity;
    }
  }

  let customerButton: React.ReactElement | null;
  const loginState = GetLogInState();
  switch (loginState) {
    case "LOGGED_IN":
      customerButton = (
        <Popover
          placement="bottom"
          content={
            <CustomerInfoCard
              customer={customer}
              err={customerErr}
              refresh={updateCustomer}
              logout={logout}
            />
          }
          trigger="click"
        >
          <Button size="large" type="primary" icon={<UserOutlined />} />
        </Popover>
      );
      break;
    case "NOT_LOGGED_IN":
      customerButton = (
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate(
              {
                pathname: `/${restaurantSlug}/login`,
                search: query.toString(),
              },
              { state: { from: location.pathname } },
            )
          }
        >Log In / 登入</Button>
      );
      break;
    case "UNSUPPORTED":
      customerButton = null;
      break;
    default:
      assertNever(loginState);
  }

  return (
    <Page
      rightButtons={
        <Space size="middle">
          {customerButton}
          <Badge count={numItems}>
            <Button
              size="large"
              type="primary"
              icon={<ShoppingCartOutlined />}
              onClick={() => {
                setShowCart(true);
                document.body.style.overflow = "hidden";
              }}
            />
          </Badge>
        </Space>
      }
    >
      <div style={{ minHeight: "15px", backgroundColor: "#2D211F" }}>
        {restaurantSlug && (
          <BannerSelector
            restaurantSlug={restaurantSlug}
            fulfillmentType={props.fulfillment_type}
            menu={menu}
          />
        )}
      </div>
      <AnnouncementsBanner />
      {menu !== null ? (
        <MenuScroll
          customer={customer}
          menu={menu}
          pointsInCart={countPointsInCart(
            new Map(Object.entries(cart.lineItems)),
          )}
          fulfillmentType={props.fulfillment_type}
          onAddToCart={(item) => addLineItem(cartScope, item)}
          groupMenuSlug={groupMenuSlug}
        />
      ) : (
        <Spin indicator={<LoadingOutlined spin />} size="large" fullscreen />
      )}
      <Cart
        customer={customer}
        cartScope={cartScope}
        fulfillmentType={props.fulfillment_type}
        groupMenu={menu?.group_menu_info}
        subtotal={subtotal}
        showCart={showCart}
        hideCart={() => setShowCart(false)}
      />
    </Page>
  );
};

export default OrderMenu;

function BannerSelector({
  restaurantSlug,
  fulfillmentType,
  menu,
}: {
  restaurantSlug: TRestaurantSlug;
  fulfillmentType: FulfillmentType;
  menu: GetMenuResponse | null;
}) {
  switch (fulfillmentType) {
    case "SHIP":
      return <ShippingBanner />;
    case "GROUP":
    case "CAMPAIGN_BATCH_DELIVERY":
      return <CampaignBanner menu={menu} />;
    case "IN_STORE":
      return <InStoreBanner />;
    case "LARGE_VOLUME_DELIVERY":
      return <LargeVolumeDeliveryBanner restaurantSlug={restaurantSlug} />;
    case "LARGE_VOLUME_PICKUP":
      return <LargeVolumePickupBanner restaurantSlug={restaurantSlug} />;
    default:
      return assertNever(fulfillmentType);
  }
}

function CampaignBanner({ menu }: { menu: GetMenuResponse | null }) {
  return (
    <div style={{ width: "100%" }}>
      {menu !== null && menu.group_menu_info && (
        <CampaignOrderBanner groupMenu={menu.group_menu_info} />
      )}
    </div>
  );
}

function InStoreBanner() {
  const query = useQuery();

  // Minimum wait time for this restaurant.
  // TODO: don't hardcode min wait minutes to 15 minutes
  const minWaitMinute = 15;

  if (IsEmployeeUser()) {
    return (
      <Alert
        type="info"
        banner
        message="電話訂單輸入系統 Phone Order Entry System"
      />
    );
  }

  return isInRestaurantOrder(query) ? (
    <InRestaurantBanner
      table_name_v2={query.get("table_name_v2")}
      table_name={query.get("table_name")}
    />
  ) : (
    <WaitTimeFloater minWaitMinute={minWaitMinute} />
  );
}

function ShippingBanner() {
  return (
    <CustomBanner>
      <Alert
        type="info"
        message={"Frozen Items Shipping / 郵寄冷凍食品"}
        description="Your order will ship on the first Monday or Tuesday after you place the order (excluding public holidays). 下單後的第一個週一或週二會寄出（公共假日除外）"
      />
    </CustomBanner>
  );
}

function LargeVolumeDeliveryBanner({
  restaurantSlug,
}: {
  restaurantSlug: TRestaurantSlug;
}) {
  return (
    <CustomBanner>
      <Title level={5}>
        <TeamOutlined /> Group Order (Delivery) / 團購 (外送)
      </Title>
      {/* <LargeVolumeDeliveryRulesDialog restaurantSlug={restaurantSlug} /> */}
    </CustomBanner>
  );
}

function LargeVolumePickupBanner({
  restaurantSlug,
}: {
  restaurantSlug: TRestaurantSlug;
}) {
  // TODO: use different banner image for catering.
  return (
    <CustomBanner>
      <Title level={5}>
        <TeamOutlined /> Group Order (Pickup) / 團購 (自取)
      </Title>
      <LargeVolumePickupRules restaurantSlug={restaurantSlug} />
    </CustomBanner>
  );
}
