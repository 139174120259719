import React from "react";
import { Card } from "antd";
import { CardProps } from "antd/lib/card";

interface CustomCardProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: string | number;
}

const CustomBanner: React.FC<CustomCardProps> = ({
  children,
  height = "100%",
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        backgroundColor: "#FFFFFF",
        flexDirection: "column",
        justifyContent: "space-between",
        alignContent: "center",
        boxSizing: "border-box",
        padding: "1rem",
        gap: 5,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default CustomBanner;
