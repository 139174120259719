import { Button, Divider, Typography, Flex, Tag } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import { useCartStore, CartObject } from "../stores/CartStore";
import { CartScope, LineItem } from "../types/ui";
import { assertNever, convertPrice } from "../utils/Util";
import NumberBar from "./NumberBar";
import DynamicImg from "./DynamicImg";
import NoPic from "../img/No_Pic.png";

const { Text } = Typography;

export function CartLineItemList({
  cartScope,
  cart,
}: {
  cartScope: CartScope;
  cart: CartObject;
}) {
  // Display paid and redeemed items separately.
  const grouped = groupBy(
    Object.entries(cart.lineItems),
    ([_, item]) => item.item_type,
  );

  // Sort to show items from the same category together.
  const sortedGroup = (group: Array<[string, LineItem]>) =>
    sortBy(group, ([_, item]) => item.item_schema.name);

  return (
    <>
      {grouped["paid"] != null &&
        sortedGroup(grouped["paid"]).map(([key, item]) => (
          <CartLineItem key={key} cartScope={cartScope} item={item} />
        ))}
      {grouped["redeemed"] != null && (
        <Divider style={{ margin: 0 }}>Redeemed</Divider>
      )}
      {grouped["redeemed"] != null &&
        sortedGroup(grouped["redeemed"]).map(([key, item]) => (
          <CartLineItem key={key} cartScope={cartScope} item={item} />
        ))}
    </>
  );
}

function CartLineItem({
  cartScope,
  item,
}: {
  cartScope: CartScope;
  item: LineItem;
}) {
  const label = item.item_schema.pos_key;
  const name = item.item_schema.name;
  const totalPriceForDisplay = (item: LineItem) => {
    switch (item.item_type) {
      case "paid":
        return `$${convertPrice(item.price * item.quantity)}`;
      case "redeemed":
        return `${item.points * item.quantity} pts`;
      default:
        assertNever(item);
    }
  };

  const unitPriceForDisplay = (item: LineItem) => {
    switch (item.item_type) {
      case "paid":
        return `$${convertPrice(item.price)} x ${item.quantity}`;
      case "redeemed":
        return `${item.points} points x ${item.quantity}`;
      default:
        assertNever(item);
    }
  };

  const descriptions = [
    ...item.modifications.map((mod) => mod.name),
    ...Object.values(item.combo_choices).map(
      (choice) => `${choice.name} x ${choice.quantity}`,
    ),
  ];
  if (item.notes) {
    descriptions.push(item.notes);
  }

  return (
    <Flex
      vertical
      justify="space-between"
      align="flex-start"
      style={{ gap: 8, width: "100%" }}
    >
      <Flex
        align="flex-start"
        justify="flex-start"
        style={{ gap: 8, width: "100%" }}
      >
        <Flex align="center" justify="flex-start" style={{ gap: 8 }}>
          <Tag bordered={false} style={{ margin: 0 }}>
            {label}
          </Tag>
          <div
            style={{
              height: "60px",
              width: "80px",
              maxHeight: "60px",
              minWidth: "80px",
            }}
          >
            {item.image_manifest != null ? (
              <DynamicImg
                manifest={item.image_manifest}
                alt={item.item_schema.name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            ) : (
              <img
                src={NoPic}
                alt="No Image Available"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            )}
          </div>
        </Flex>

        <Flex vertical align="flex-start" style={{ width: "100%", gap: 5 }}>
          <Text
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {name}
          </Text>
          <Text strong style={{ wordBreak: "keep-all" }}>
            {totalPriceForDisplay(item)}
          </Text>
          <CartLineItemControl cartScope={cartScope} item={item} />
          <Text type="secondary">{unitPriceForDisplay(item)}</Text>
          {descriptions.map((s) => (
            <Text type="secondary">{s}</Text>
          ))}
        </Flex>
      </Flex>
      <Divider style={{ margin: "16px 0" }} />
    </Flex>
  );
}

function CartLineItemControl({
  cartScope,
  item,
}: {
  cartScope: CartScope;
  item: LineItem;
}) {
  const updateLineItemQty = useCartStore((state) => state.updateLineItemQty);
  const removeLineItem = useCartStore((state) => state.removeLineItem);

  return (
    <Flex
      align="center"
      justify="space-between"
      gap={20}
      style={{ width: "100%" }}
    >
      <div style={{ width: "60%" }}>
        <NumberBar
          min={1}
          size="small"
          value={item.quantity}
          onChange={(qty: number) => {
            updateLineItemQty(cartScope, item, qty);
          }}
        />
      </div>
      <Button
        // type="primary"
        icon={<DeleteOutlined />}
        onClick={() => removeLineItem(cartScope, item)}
      />
    </Flex>
  );
}
