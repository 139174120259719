import React from "react";

import { Collapse, Spin, Typography } from "antd";

import { BankOutlined } from "@ant-design/icons";
import moment from "moment";

import { GroupMenuInfo } from "../types/api";
import { RestaurantContext } from "../layouts/Page";
import {
  localDate,
  localTime,
  formatTime,
  getHourAndMinute,
} from "../utils/Util";
import styles from "../css/Banner.module.css";

const { Paragraph, Text, Title } = Typography;
const { Panel } = Collapse;

type CampaignOrderBannerProps = {
  groupMenu: GroupMenuInfo;
};

function FulfillmentWindowDays({ groupMenu }: { groupMenu: GroupMenuInfo }) {
  let start: string | null = null;
  let end: string | null = null;

  if (groupMenu.fulfillment_start_date != null) {
    start = localDate(moment(groupMenu.fulfillment_start_date).toDate());
  }
  if (groupMenu.fulfillment_end_date != null) {
    end = localDate(moment(groupMenu.fulfillment_end_date).toDate());
  }

  if (start == null && end == null) {
    return null;
  } else if (start == null && end != null) {
    return <Text>Pickup Deadline / 最後取餐日期: {end}</Text>;
  } else if (start != null && end == null) {
    return <Text>Earliest Pickup Date / 最早取餐日期: {start}</Text>;
  } else if (start === end) {
    return <Text>Pickup Date / 取餐日期: {start}</Text>;
  } else {
    return (
      <Text>
        Pickup Dates / 取餐日期: {start} - {end}
      </Text>
    );
  }
}

function FulfillmentWindowHours({ groupMenu }: { groupMenu: GroupMenuInfo }) {
  const restaurant = React.useContext(RestaurantContext);

  if (
    groupMenu.fulfillment_time_start_second == null &&
    groupMenu.fulfillment_time_end_second == null
  ) {
    return null;
  }

  const [openHour, openMinute] = getHourAndMinute(
    groupMenu.fulfillment_time_start_second ?? restaurant?.open_second ?? 0,
  );
  const [closeHour, closeMinute] = getHourAndMinute(
    groupMenu.fulfillment_time_end_second ?? restaurant?.close_second ?? 75600, // 75600 seconds = 9PM
  );
  const t1 = formatTime(openHour, openMinute);
  const t2 = formatTime(closeHour, closeMinute);
  if (t1 === t2) {
    return <Text>Pickup Time / 取餐時間: {t1}</Text>;
  }
  return (
    <Text>
      Pickup Hours / 取餐時間: {t1} - {t2}
    </Text>
  );
}

export default (props: CampaignOrderBannerProps) => {
  const restaurant = React.useContext(RestaurantContext);

  if (restaurant == null) {
    return <Spin size="large" />;
  }

  return (
    <div className={styles.Banner}>
      <div style={{ width: "90vw", margin: "auto" }}>
        <Title level={3} style={{ overflowWrap: "break-word" }}>
          {restaurant.display_name}
        </Title>
        {props.groupMenu.description != null ? (
          <Collapse defaultActiveKey={"only-key"} ghost>
            <Panel
              key="only-key"
              header={<Title level={5}>{props.groupMenu.name}</Title>}
            >
              {props.groupMenu.description.split("\\n").map((p) => (
                <Paragraph>{p}</Paragraph>
              ))}
            </Panel>
          </Collapse>
        ) : (
          restaurant.address != null && (
            <div>
              <Text type="secondary">
                <BankOutlined />
                &nbsp;
              </Text>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  restaurant.address,
                )}`}
              >
                {restaurant.address}
              </a>
            </div>
          )
        )}
        <div>
          <Text>
            Order Deadline / 訂餐期限:{" "}
            {localTime(moment(props.groupMenu.order_deadline).toDate())}
          </Text>
        </div>
        <div>
          <FulfillmentWindowDays groupMenu={props.groupMenu} />
        </div>
        <div>
          <FulfillmentWindowHours groupMenu={props.groupMenu} />
        </div>
      </div>
    </div>
  );
};
