// O2 Valley specific config
//
import { ImageManifest } from "../types/api";

export const o2BannerImgManifest: ImageManifest = {
  default: {
    url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/1800x1200.jpg",
    type: "image/jpeg",
    width: 1800,
    height: 1200,
  },
  all: [
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/450x300.webp",
      type: "image/webp",
      width: 450,
      height: 300,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/800x533.webp",
      type: "image/webp",
      width: 800,
      height: 533,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/1200x800.webp",
      type: "image/webp",
      width: 1200,
      height: 800,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/1800x1200.webp",
      type: "image/webp",
      width: 1800,
      height: 1200,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/3000x2000.webp",
      type: "image/webp",
      width: 3000,
      height: 2000,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/450x300.jpg",
      type: "image/jpeg",
      width: 450,
      height: 300,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/800x533.jpg",
      type: "image/jpeg",
      width: 800,
      height: 533,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/1200x800.jpg",
      type: "image/jpeg",
      width: 1200,
      height: 800,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/1800x1200.jpg",
      type: "image/jpeg",
      width: 1800,
      height: 1200,
    },
    {
      url: "https://s3-us-west-2.amazonaws.com/usercontent.ticketrails.com/rst_ogYUfU3LS-yz4Pgy_oBTYA/banner/3000x2000.jpg",
      type: "image/jpeg",
      width: 3000,
      height: 2000,
    },
  ],
};

// O2 valley franchise
export const FRANCHISE_ID =
  process.env.NODE_ENV === "development"
    ? "frc_wOG97SJ-T3aTueJcdRHHoA"
    : "frc_dfq5SkcLTeWhDG80uoJu7A";

export const DEFAULT_RESTAURANT_SLUG = "cupertino";
export const PALO_ALTO_SLUG = "palo-alto";
export const RESTAURANT_SLUGS = [
  DEFAULT_RESTAURANT_SLUG,
  PALO_ALTO_SLUG,
] as const;
export type TRestaurantSlug = (typeof RESTAURANT_SLUGS)[number];

export const RESTAURANT_NAMES: {
  [slug in TRestaurantSlug]: string;
} = {
  [DEFAULT_RESTAURANT_SLUG]: "Cupertino",
  [PALO_ALTO_SLUG]: "Palo Alto",
};
export const RESTAURANT_IDS: {
  [slug in TRestaurantSlug]: string;
} =
  process.env.NODE_ENV === "development"
    ? {
        // Dev env restaurant ids
        // O3 Canyon
        [DEFAULT_RESTAURANT_SLUG]: "rst_CWNf_VBxQL6YEWSFe4C7RQ",
        // O4 Gorge
        [PALO_ALTO_SLUG]: "rst_vQ9-gZU6SZeS0nmkYHH30w",
      }
    : {
        // Prod env restaurant ids
        [DEFAULT_RESTAURANT_SLUG]: "rst_ogYUfU3LS-yz4Pgy_oBTYA",
        [PALO_ALTO_SLUG]: "rst_2nWzTgaAT8K-eS7jfprmkA",
      };
