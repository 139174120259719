import { Location } from "history";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { FormOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  notification,
  Typography,
} from "antd";

import { SetCredentials } from "../utils/Auth";
import { useQuery } from "../hooks/useQuery";
import { NotifyError } from "../utils/NotifyError";
import {
  DEFAULT_RESTAURANT_SLUG,
  RESTAURANT_IDS,
  TRestaurantSlug,
} from "../constants/o2";
import Page from "../layouts/Page";
import { CreateCustomerRequest, CreateCustomerResponse } from "../types/api";
import { API_URL, fetchT } from "../utils/Util";
import CustomCard from "../components/CustomCard";

const { Title, Text } = Typography;

type LocationState = {
  from: Location;
};

const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { restaurantSlug } = useParams<{ restaurantSlug: TRestaurantSlug }>();

  const signupRedirect = () => {
    let pathname = "/";
    if ((location.state as LocationState)?.from) {
      pathname = (location.state as LocationState).from.pathname;
    }
    navigate(
      {
        pathname: pathname,
        search: query.toString(),
      },
      { replace: true },
    );
  };

  const onFinish = (req: CreateCustomerRequest) => {
    fetchT({
      method: "POST",
      url: `${API_URL}/v1/public/r/${RESTAURANT_IDS[DEFAULT_RESTAURANT_SLUG]}/customers`,
      body: req,
      handleResponse: (resp: CreateCustomerResponse) => {
        SetCredentials(resp.id, {
          email_or_phone: req.email_or_phone,
          password: req.password,
        });
        // TODO: don't hardcode restaurant name.
        notification.success({
          message: "Sign Up Success!",
          description: "You are now a member of O2 Valley!",
        });
        signupRedirect();
      },
      handleError: (err: Error) => {
        NotifyError("Sign Up Failed", err);
      },
    });
  };

  const formElem = (
    <Form
      labelAlign="left"
      layout="vertical"
      initialValues={{ allow_marketing: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        label="Name / 名字"
        rules={[
          {
            required: true,
            message: "Please enter your name!",
          },
          {
            message: "Name cannot exceed 256 characters.",
            type: "string",
            max: 256,
          },
        ]}
      >
        <Input placeholder="O2 Bunny" size="large" />
      </Form.Item>

      <Form.Item
        label="Email 電⼦郵箱 or Phone 電話"
        name="email_or_phone"
        rules={[
          {
            required: true,
            message: "Please enter your email or phone number!",
          },
          {
            // RFC 3696 with errata limits email to 254 characters
            message: "Email cannot exceed 256 characters.",
            type: "string",
            max: 256,
          },
        ]}
      >
        <Input placeholder="example@email.com" size="large" />
      </Form.Item>

      <Form.Item
        label="Password 密碼"
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter your password!",
          },
          {
            message: "Password cannot exceed 512 characters.",
            type: "string",
            max: 512,
          },
        ]}
      >
        <Input type="password" placeholder="Enter Password" size="large" />
      </Form.Item>
      <Form.Item name="allow_marketing" valuePropName="checked">
        <Checkbox style={{ textAlign: "left" }}>
          Receive promotions from O2 Valley (Less than 1 per month)
          <br />
          <Text type="secondary">Message and data rates may apply</Text>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Text>
          By signing up, you authorize O2 Valley to send account management
          messages via email or text message.
        </Text>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block size="large">
          <FormOutlined /> Sign Up / 註冊
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Page>
      <CustomCard>
        <Title level={3}>Create an Account</Title>
        {formElem}
        <Divider style={{ margin: 0 }}>or</Divider>
        <Text>Already have an account?</Text>
        <Button
          type="primary"
          block
          size="large"
          style={{ height: "auto", whiteSpace: "normal" }}
          onClick={() =>
            navigate(
              {
                pathname: `/${restaurantSlug}/login`,
                search: query.toString(),
              },
              { state: location.state },
            )
          }
        >
          Log in / 登入
        </Button>
      </CustomCard>
    </Page>
  );
};

export default SignUpPage;
