import { Response } from "node-fetch";

import { ImageManifest } from "../types/api";
import {
  ItemSchema,
  RestaurantPublicInfo,
  RestaurantFeatureFlag,
} from "../types/model";

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://o2-valley.com:5000"
    : "http://localhost:5000";

const logAndHandleError = (err: Error, handleError?: (e: Error) => void) => {
  console.error(err);
  if (handleError) {
    handleError(err);
  }
};

const processError = (
  err: Error | Response,
  handleError?: (e: Error) => void,
) => {
  if (err instanceof Error) {
    logAndHandleError(err, handleError);
  } else {
    err.text().then((body) => logAndHandleError(new Error(body), handleError));
  }
};

export const getFetch = <T extends object>(
  url: string,
  handleResponse: (resp: T) => void,
  handleError?: (e: Error) => void,
) => {
  fetchT({
    method: "GET",
    url: url,
    handleResponse: handleResponse,
    handleError: handleError,
  });
};

export type fetchTArgs<T extends object> = {
  method: string;
  url: string;

  auth?: string;
  body?: object;

  handleResponse: (resp: T) => void;
  handleError?: (e: Error) => void;
};

export const fetchT = <T extends object>(args: fetchTArgs<T>) => {
  const fetchArgs: any = {
    method: args.method,
    headers: {},
  };
  if (args.auth) {
    fetchArgs.headers["Authorization"] = `Basic ${args.auth}`;
  }
  if (args.body) {
    fetchArgs.headers["Content-Type"] = "application/json";
    fetchArgs.body = JSON.stringify(args.body);
  }

  fetch(args.url, fetchArgs)
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      res.json().then((respJSON) => {
        args.handleResponse(respJSON);
      });
    })
    .catch((err) => {
      processError(err, args.handleError);
    });
};

export const convertPrice = (
  price: number,
  opts?: { dropZero?: boolean },
): string => {
  const whole = Math.trunc(price / 100);
  const frac = Math.trunc(price % 100);

  let fracString = frac.toString();
  if (frac <= 9) {
    fracString = "0" + fracString;
  }

  if (opts?.dropZero === true && frac === 0) {
    return `${whole}`;
  }
  return `${whole}.${fracString}`;
};

export const vowels = "aeiouAEIOU";

export const formatTime = (hour: number, minute: number) => {
  let hourStr = `${hour}`;
  if (hour === 0) {
    hourStr = "12";
  }
  if (hour > 12) {
    hourStr = `${hour % 12}`;
  }
  let minuteStr = `${minute}`;
  if (minute < 10) {
    minuteStr = `0${minute}`;
  }
  let amPM = "AM";
  if (hour >= 12) {
    amPM = "PM";
  }
  return `${hourStr}:${minuteStr}${amPM}`;
};

type HourAndMinute = {
  hour: number;
  minute: number;
};

// Assumes openTime and closeTime are at least an hour apart
export const isInBetweenTime = (
  time: HourAndMinute,
  openTime: HourAndMinute,
  closeTime: HourAndMinute,
) => {
  if (time.hour > openTime.hour && time.hour < closeTime.hour) {
    return true;
  } else if (time.hour === openTime.hour) {
    return time.minute >= openTime.minute;
  } else if (time.hour === closeTime.hour) {
    return time.minute < closeTime.minute;
  }
  return false;
};

export const getHourAndMinute = (seconds: number) => {
  const hour = Math.floor(seconds / 60 / 60);
  const minute = Math.floor((seconds - hour * 60 * 60) / 60);
  return [hour, minute];
};

export const getHourInfoStr = (
  restaurant: RestaurantPublicInfo | undefined,
) => {
  if (
    !restaurant ||
    restaurant.open_second === undefined ||
    restaurant.close_second === undefined
  ) {
    return undefined;
  }

  const [openHour, openMinute] = getHourAndMinute(restaurant.open_second);
  const [closeHour, closeMinute] = getHourAndMinute(restaurant.close_second);
  const t1 = formatTime(openHour, openMinute);
  const t2 = formatTime(closeHour, closeMinute);
  return `Sun-Sat, ${t1} - ${t2}`;
};

export const localTime = (t: Date) => {
  return t.toLocaleString("en-ZA", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

export const localDate = (t: Date) => {
  return t.toLocaleDateString("en-ZA", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

export function getImageManifestForItem(
  item: ItemSchema,
): ImageManifest | undefined {
  if (item.image_manifest !== undefined) {
    return item.image_manifest;
  }
  if (item.image_url !== "" && item.image_url !== undefined) {
    return {
      default: {
        url: item.image_url,
        type: "image/jpeg",
        width: 0,
        height: 0,
      },
    };
  }
  return undefined;
}

export function assertNever(v: never): never {
  throw new Error(`should never get ${v}`);
}

export class DefaultMap<K, V> {
  // TODO: extend Map<K, V> instead of maintaining internal map.
  private data: Map<K, V>;
  private defaultFunc: () => V;

  constructor(defaultFunc: () => V) {
    this.data = new Map();
    this.defaultFunc = defaultFunc;
  }

  get(key: K): V {
    const existing = this.data.get(key);
    if (existing != null) {
      return existing;
    }

    const newItem = this.defaultFunc();
    this.data.set(key, newItem);
    return newItem;
  }

  delete(key: K) {
    this.data.delete(key);
  }

  getMap(): ReadonlyMap<K, V> {
    return this.data;
  }
}

export class FlagContainer {
  private flags: Map<string, boolean>;

  constructor(fs: ReadonlyArray<RestaurantFeatureFlag>) {
    this.flags = new Map();
    for (const f of fs) {
      this.flags.set(f.flag_name, f.enabled);
    }
  }

  public isEnabled(flagName: string): boolean {
    return this.flags.get(flagName) ?? false;
  }
}
