import {
  Button,
  Carousel,
  Divider,
  Flex,
  Grid,
  Select,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import HeroImage from "../img/Hero_Image.jpg";
import HeroMobileImage from "../img/Hero_Image_Mobile.jpg";

import O2Bunny from "../img/O2_Bunny.png";
import Logo from "../img/logo.svg";

import { useQuery } from "../hooks/useQuery";
import {
  RESTAURANT_IDS,
  RESTAURANT_NAMES,
  RESTAURANT_SLUGS,
  TRestaurantSlug,
} from "../constants/o2";
import Page from "../layouts/Page";
import { RestaurantPublicInfo } from "../types/model";
import { API_URL, getFetch, getHourInfoStr } from "../utils/Util";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;

export default () => {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();
  const [selectedRestaurantSlug, setSelectedRestaurantSlug] =
    useState<TRestaurantSlug>();

  const handleChange = (value: TRestaurantSlug) => {
    setSelectedRestaurantSlug(value);
  };

  function GroupOrderButton() {
    return (
      <Button
        type="primary"
        size="large"
        block
        onClick={() => navigate(`/${selectedRestaurantSlug}/group`)}
        disabled={selectedRestaurantSlug === undefined}
      >
        Large Group Order / 團購
      </Button>
    );
  }

  function OrderButton() {
    return (
      <Button
        type="primary"
        size="large"
        block
        onClick={() => navigate(`/${selectedRestaurantSlug}/menu`)}
        disabled={selectedRestaurantSlug === undefined}
      >
        Dine-in or Takeout / 內用或外帶
      </Button>
    );
  }

  function LocationCard() {
    const [restaurantInfo, setRestaurantInfo] = useState<
      RestaurantPublicInfo | undefined
    >(undefined);

    useEffect(() => {
      if (selectedRestaurantSlug) {
        getFetch<RestaurantPublicInfo>(
          `${API_URL}/v1/public/r/${RESTAURANT_IDS[selectedRestaurantSlug]}/info`,
          (res) => {
            if (res) {
              setRestaurantInfo(res);
            }
          },
        );
      }
    }, [selectedRestaurantSlug]);

    const hourInfo: string | undefined = getHourInfoStr(restaurantInfo);

    return (
      <Flex vertical align="center" justify="center" gap={16}>
        <img src={Logo} alt="O2 Valley Logo" width="200px" />
        {/* <Title level={5}>Start Your Order by Selecting a Location</Title> */}
        <Select
          value={selectedRestaurantSlug}
          onChange={handleChange}
          placeholder="Select a location to start ordering"
          style={{ width: "100%" }}
          size="large"
        >
          {RESTAURANT_SLUGS.map((slug) => (
            <Option key={slug} value={slug}>
              {RESTAURANT_NAMES[slug]}
            </Option>
          ))}
        </Select>
        <Flex
          vertical
          align="center"
          justify="space-between"
          style={{
            height: "150px",
            minWidth: "300px",
            borderRadius: 8,
            padding: "1rem",
            backgroundColor: "#DEC623",
          }}
        >
          {selectedRestaurantSlug ? (
            <Divider style={{ margin: 0 }}>
              <Title level={4}>
                {RESTAURANT_NAMES[selectedRestaurantSlug]}
              </Title>
            </Divider>
          ) : (
            <Flex vertical align="center" justify="center">
              <img src={O2Bunny} alt="O2 Bunny" style={{ height: "100px" }} />
              <Text>Please select a location from above</Text>
            </Flex>
          )}
          {restaurantInfo && <Text>{hourInfo}</Text>}
          {restaurantInfo && <Text>{restaurantInfo.address}</Text>}
        </Flex>
        <OrderButton />
        <GroupOrderButton />
      </Flex>
    );
  }

  if (query.get("table_id")) {
    // This is a table-side order, straight to menu page.
    navigate(`/menu?${query.toString()}`, { replace: true });
  }

  useEffect(() => {
    if (location.state && location.state.restaurantSlug) {
      setSelectedRestaurantSlug(location.state.restaurantSlug);
    }
  }, [location.state]);
  return (
    <Page header={false}>
      <Flex
        vertical
        justify="center"
        align="center"
        style={{
          height: "90vh",
          // backgroundColor: "#2D211F",
          background: `url(${screens.sm ? HeroImage : HeroMobileImage}) center / cover no-repeat`,
        }}
      >
        <LocationCard />
      </Flex>
      <Carousel></Carousel>
    </Page>
  );
};
