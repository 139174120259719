import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Flex, Grid, Layout, Typography } from "antd";
import YelpSVG from "../components/YelpSVG";

import { useQuery } from "../hooks/useQuery";
import ErrorBoundary from "../components/ErrorBoundary";
import { ReactComponent as Logo } from "../img/logo.svg";
import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import { RestaurantPublicInfo, RestaurantFeatureFlag } from "../types/model";
import { API_URL, getFetch, FlagContainer } from "../utils/Util";
import { LOGO_PATH_OVERRIDE_QUERY_PARAM } from "../constants/constants";
import { Footer } from "antd/es/layout/layout";
const { useBreakpoint } = Grid;
import Icon, {
  FacebookOutlined,
  GoogleOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
const { Header, Content } = Layout;

type PageProps = {
  rightButtons?: React.ReactNode;
  header?: boolean;
  children: React.ReactNode;
};

export const RestaurantContext = React.createContext<
  RestaurantPublicInfo | undefined
>(undefined);

export const RestaurantFeatureFlagsContext = React.createContext<
  FlagContainer | undefined
>(undefined);

const Page: React.FC<PageProps> = ({
  rightButtons,
  header = true,
  children,
}) => {
  const screens = useBreakpoint();
  const { restaurantSlug } = useParams<{
    restaurantSlug: TRestaurantSlug;
  }>();
  const [restaurantInfo, setRestaurantInfo] = useState<
    RestaurantPublicInfo | undefined
  >(undefined);
  const [flags, setFlags] = useState<FlagContainer | undefined>(undefined);
  const [socialMediaButtons, setSocialMediaButtons] = useState<
    React.ReactElement[]
  >([]);

  const query = useQuery();

  useEffect(() => {
    if (restaurantSlug) {
      getFetch<RestaurantPublicInfo>(
        `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/info`,
        (res) => {
          if (res) {
            setRestaurantInfo(res);
          }
        },
      );

      getFetch<{ flags?: ReadonlyArray<RestaurantFeatureFlag> }>(
        `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/feature_flags`,
        (res) => {
          if (res) {
            setFlags(new FlagContainer(res.flags ?? []));
          }
        },
      );
    }
  }, [restaurantSlug]);

  let logoPath = "/";
  const logoLinkOverride = query.get(LOGO_PATH_OVERRIDE_QUERY_PARAM);
  if (logoLinkOverride != null) {
    logoPath = logoLinkOverride;
  }

  const transformSlug = (slug: string): string => {
    return slug
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    if (restaurantInfo) {
      const buttons = [];

      if (restaurantInfo.instagram_url) {
        buttons.push(
          <Button
            size="large"
            type="text"
            icon={<InstagramOutlined style={{ color: "gray" }} />}
            href={restaurantInfo.instagram_url}
            key="instagram"
          />,
        );
      }

      if (restaurantInfo.google_url) {
        buttons.push(
          <Button
            size="large"
            type="text"
            icon={<GoogleOutlined style={{ color: "gray" }} />}
            href={restaurantInfo.google_url}
            key="google"
          />,
        );
      }

      if (restaurantInfo.yelp_url) {
        buttons.push(
          <Button
            size="large"
            type="text"
            icon={<Icon component={YelpSVG} style={{ color: "gray" }} />}
            href={restaurantInfo.yelp_url}
            key="yelp"
          />,
        );
      }

      if (restaurantInfo.facebook_url) {
        buttons.push(
          <Button
            size="large"
            type="text"
            icon={<FacebookOutlined style={{ color: "gray" }} />}
            href={restaurantInfo.facebook_url}
            key="facebook"
          />,
        );
      }

      setSocialMediaButtons(buttons);
    }
  }, [restaurantInfo]);

  return (
    <Layout style={{ minHeight: "100%" }}>
      <RestaurantFeatureFlagsContext.Provider value={flags}>
        <RestaurantContext.Provider value={restaurantInfo}>
          {header && (
            <Header
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: screens.md ? "0 4rem" : "0 1rem", // Apply padding only for medium and above breakpoints
              }}
            >
              <Flex align="center">
                <Link
                  to={{ ...location, pathname: logoPath }}
                  state={{ restaurantSlug }}
                  style={{
                    display: "flex",
                    height: "100%",
                    alignContent: "center",
                  }}
                >
                  <Logo
                    title={
                      (restaurantInfo && restaurantInfo.display_name) || "logo"
                    }
                    style={{
                      height: "auto",
                      width: screens.md ? "100%" : "100px", // Set width to 120px for smaller than medium breakpoints
                      maxWidth: "200px",
                      minWidth: screens.md ? "120px" : "auto", // Adjust min-width based on breakpoint
                    }}
                  />
                </Link>
                {restaurantSlug &&
                  (screens.md ? (
                    <Typography.Title level={3} style={{ color: "#FFFFFF" }}>
                      {transformSlug(restaurantSlug)}
                    </Typography.Title>
                  ) : (
                    <Typography.Text style={{ color: "#FFFFFF" }}>
                      {transformSlug(restaurantSlug)}
                    </Typography.Text>
                  ))}
              </Flex>
              {rightButtons}
            </Header>
          )}
          <Content style={{ height: "100%" }}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <Flex vertical align="center" justify="center">
              <div>{socialMediaButtons}</div>
              <Typography.Text>
                O2 Valley ©{new Date().getFullYear()} | Created by O2 Valley
              </Typography.Text>
            </Flex>
          </Footer>
        </RestaurantContext.Provider>
      </RestaurantFeatureFlagsContext.Provider>
    </Layout>
  );
};

export default Page;
