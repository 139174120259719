import { ComboChoices, ImageManifest } from "./api";
import { FulfillmentType, ItemSchema } from "./model";

// Controls what the cart applies to (e.g. when navigating to a different
// location, the cart is changed).
export type CartScope = {
  restaurantSlug: string;
  groupMenuSlug?: string;
  fulfillmentType: FulfillmentType;
};

export type IDAndName = {
  id: string;
  name: string;
};

export interface LineItemBase {
  item_schema: ItemSchema;
  modifications: IDAndName[];
  combo_choices: ComboChoices;
  notes: string;
  quantity: number;
  image_manifest?: ImageManifest;
}

export interface PaidLineItem extends LineItemBase {
  item_type: "paid";
  // The price here is per-unit and includes any adjustments from modifications.
  price: number;
}

export interface RedeemedLineItem extends LineItemBase {
  item_type: "redeemed";
  // The points here is per-unit and includes any adjustments from modifications.
  points: number;
}

export type LineItem = PaidLineItem | RedeemedLineItem;

export function IsPaidLineItem(
  item: PaidLineItem | RedeemedLineItem,
): item is PaidLineItem {
  return item.item_type === "paid";
}

export function IsRedeemedLineItem(
  item: PaidLineItem | RedeemedLineItem,
): item is RedeemedLineItem {
  return item.item_type === "redeemed";
}
