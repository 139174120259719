import React from "react";
import { Button, Flex } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

export interface NumberBarProps {
  min?: number;
  max?: number;
  value?: number;
  onChange?: (t: number) => void;
  size?: "large" | "small";
}

const NumberBar: React.FC<NumberBarProps> = (props: NumberBarProps) => {
  const [currValue, setCurrValue] = React.useState<number>(props.value ?? 1);

  React.useEffect(() => {
    if (props.value != null) {
      setCurrValue(props.value);
    }
  }, [props.value]);

  const onChange = (newValue: number) => {
    if (props.min !== undefined && newValue < props.min) {
      newValue = props.min;
    }
    if (props.max !== undefined && newValue > props.max) {
      newValue = props.max;
    }
    setCurrValue(newValue);
    props.onChange?.(newValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      onChange(value);
    }
  };

  const onButton = (delta: number) => {
    onChange(currValue + delta);
  };

  return (
    <Flex align="center" justify="space-between" style={{ width: "100%" }}>
      <Button
        size={props.size ?? "large"}
        disabled={props.min ? currValue === props.min : false}
        onClick={() => onButton(-1)}
        icon={<MinusOutlined />}
        // type="primary"
        style={{ padding: props.size === "small" ? "0.5rem" : "1rem" }}
      />
      <input
        type="text"
        value={currValue}
        onChange={handleInputChange}
        style={{
          textAlign: "center",
          fontSize: props.size === "small" ? "14px" : "18px",
          height: props.size === "small" ? "24px" : "32px",
          border: 0,
          width: "100%",
          flexGrow: 1,
        }}
      />
      <Button
        size={props.size ?? "large"}
        disabled={props.max ? currValue === props.max : false}
        onClick={() => onButton(1)}
        icon={<PlusOutlined />}
        // type="primary"
        style={{ padding: props.size === "small" ? "0.5rem" : "1rem" }}
      />
    </Flex>
  );
};

export default NumberBar;
