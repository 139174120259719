import {
  ClockCircleOutlined,
  DollarCircleOutlined,
  NumberOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Divider, Flex, Result, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "../hooks/useQuery";
import { ExtractErrorDetail } from "../utils/NotifyError";
import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import Page from "../layouts/Page";
import { formatTimeString, formatDateString } from "../utils/Time";
import { OrderProgress } from "../types/api";
import { FulfillmentType } from "../types/model";
import { API_URL, getFetch } from "../utils/Util";
import { useCartStore } from "../stores/CartStore";
import CustomCard from "../components/CustomCard";
const { Title, Text, Paragraph } = Typography;

type CheckoutSuccessProps = {
  fulfillment_type: FulfillmentType;
};

export default (props: CheckoutSuccessProps) => {
  const { restaurantSlug, groupMenuSlug } = useParams<{
    restaurantSlug: TRestaurantSlug;
    groupMenuSlug?: string;
  }>();

  const query = useQuery();
  const orderID = query.get("order_id") || "";
  const textNotify = query.get("text_notify") === "true";

  const [orderProgress, setOrderProgress] = useState<OrderProgress | null>(
    null,
  );
  const [fetchErr, setFetchErr] = useState<string | null>(null);

  const clearCart = useCartStore((state) => state.clearScope);

  useEffect(() => {
    // Clear cart info in session storage if order was successfully submitted
    if (restaurantSlug) {
      clearCart({
        restaurantSlug,
        groupMenuSlug,
        fulfillmentType: props.fulfillment_type,
      });

      sessionStorage?.removeItem("cart_utensils");
      sessionStorage?.removeItem("cart_notes");
      sessionStorage?.removeItem("cart_pickup_time");
      sessionStorage?.removeItem("cart_customer_name");
      sessionStorage?.removeItem("cart_customer_email");
      sessionStorage?.removeItem("cart_customer_phone");
      sessionStorage?.removeItem("cart_customer_address");
    }
  }, [props.fulfillment_type]);

  useEffect(() => {
    if (restaurantSlug) {
      getFetch<OrderProgress>(
        `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/order_progress?order_id=${orderID}`,
        (res) => {
          if (res) {
            setOrderProgress(res);
          }
        },
        (err: Error) => {
          const { description } = ExtractErrorDetail(err);
          setFetchErr(description);
        },
      );
    }
  }, [orderID]);

  if (orderProgress) {
    return (
      <Page>
        <Result
          status="success"
          title="Thank you for your order!"
          subTitle={
            props.fulfillment_type === "SHIP"
              ? "We will email you when your order ships."
              : props.fulfillment_type === "GROUP"
                ? "Please pick up your order with your group."
                : textNotify && "We will text you when your order is ready."
          }
        />
        <CustomCard>
          <Title level={5}>
            <SolutionOutlined />
            Order Number
          </Title>
          <Title level={3} copyable>
            {orderProgress.pos_order_name}
          </Title>
          <Divider style={{ margin: 0 }} />
          {orderProgress.estimated_pickup_time &&
            props.fulfillment_type === "IN_STORE" && (
              <Flex
                align="center"
                justify="center"
                gap={8}
                vertical
                style={{ textAlign: "left" }}
              >
                <Text>
                  <ClockCircleOutlined /> Estimated Pickup Time
                </Text>
                <Title level={5}>
                  {formatTimeString(orderProgress.estimated_pickup_time)}{" "}
                </Title>
              </Flex>
            )}
          {orderProgress.estimated_pickup_time &&
            (props.fulfillment_type === "LARGE_VOLUME_DELIVERY" ||
              props.fulfillment_type === "LARGE_VOLUME_PICKUP") && (
              <Flex
                align="center"
                justify="center"
                gap={5}
                vertical
                style={{ textAlign: "left" }}
              >
                <Text>
                  <ClockCircleOutlined /> Estimated{" "}
                  {props.fulfillment_type === "LARGE_VOLUME_DELIVERY"
                    ? "Delivery"
                    : "Pickup"}{" "}
                  Time
                </Text>
                <Title level={5}>
                  {formatTimeString(orderProgress.estimated_pickup_time)}
                </Title>
              </Flex>
            )}
          {orderProgress.item_progress && (
            <Flex
              align="center"
              justify="center"
              gap={5}
              vertical
              style={{ textAlign: "left" }}
            >
              <Text>
                <NumberOutlined /> Number of Items
              </Text>
              <Title level={4}>{orderProgress.item_progress.length}</Title>
            </Flex>
          )}
          {orderProgress.point_credit && (
            <Flex
              align="center"
              justify="center"
              gap={5}
              vertical
              style={{ textAlign: "left" }}
            >
              <Text>
                <DollarCircleOutlined /> Points Earned{" "}
              </Text>
              <Title level={4}>{orderProgress.point_credit.amount}</Title>
              <Text type="secondary">
                Expires On:{" "}
                {formatDateString(orderProgress.point_credit.expire_time)}
              </Text>
            </Flex>
          )}
        </CustomCard>
      </Page>
    );
  } else if (fetchErr !== null) {
    return (
      <Page>
        <Result
          status="error"
          title="Oops, something went wrong!"
          subTitle="Please try refreshing the page."
        >
          <Title level={5}>Debug Information</Title>
          <Paragraph
            code
            copyable
          >{`order_id=${orderID},err="${fetchErr}"`}</Paragraph>
        </Result>
      </Page>
    );
  } else {
    return (
      <Page>
        <Spin size="large" />
      </Page>
    );
  }
};
