import React from "react";

import { Button, Col, Divider, InputNumber, Row, Typography } from "antd";

import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import DynamicImg from "./DynamicImg";
import { ComboConfig, ItemSchema } from "../types/model";
import { ComboChoices } from "../types/api";
import { getImageManifestForItem } from "../utils/Util";

const { Title } = Typography;

export interface ComboItemSelectorProps {
  comboConfig: ComboConfig;
  value?: ComboChoices;
  onChange?: (newSelection: ComboChoices) => void;
}

const ComboItemSelector: React.FC<ComboItemSelectorProps> = (
  props: ComboItemSelectorProps,
) => {
  const [currValue, setCurrValue] = React.useState<ComboChoices>(
    props.value ?? {},
  );

  const valueUpdater = (itemID: string) => {
    return (name: string, v: number) => {
      setCurrValue((prev) => {
        const newValues = { ...prev };
        newValues[itemID] = {
          name: name,
          quantity: v,
        };
        if (props.onChange !== undefined) {
          props.onChange(newValues);
        }
        return newValues;
      });
    };
  };

  props.comboConfig.item_choices.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });
  const rows = props.comboConfig.item_choices.map((item: ItemSchema) => {
    // Figure out how many we can select for this item.
    let quota = props.comboConfig.num_items;
    for (const [id, choice] of Object.entries(currValue)) {
      if (id !== item.id) {
        quota = quota - choice.quantity;
      }
    }

    return (
      <ItemChoiceRow
        key={item.id}
        item={item}
        max={quota > 0 ? quota : 0}
        value={currValue[item.id]?.quantity ?? 0}
        onChange={valueUpdater(item.id)}
      />
    );
  });
  return (
    <>
      <Title level={4}>
        Select {props.comboConfig.num_items} items / 請選擇{" "}
        {props.comboConfig.num_items} 包
      </Title>
      {rows}
    </>
  );
};

function ItemChoiceRow({
  item,
  value,
  onChange,
  max,
}: {
  item: ItemSchema;
  value: number;
  onChange: (name: string, v: number) => void;
  max: number;
}) {
  const onChangeWrapped = (v: number | string | null) => {
    if (v === null) {
      return;
    } else if (typeof v === "string") {
      const n = parseInt(v);
      onChange(item.name, isNaN(n) ? 0 : n);
    } else {
      onChange(item.name, isNaN(v) ? 0 : v);
    }
  };

  const imgManifest = getImageManifestForItem(item);
  return (
    <>
      <Title level={5}>{item.name}</Title>
      <Row gutter={[8, 8]}>
        <Col span={16}>
          {imgManifest && (
            <DynamicImg
              alt={item.name}
              manifest={imgManifest}
              style={{ maxWidth: "100%", maxHeight: "120px" }}
            />
          )}
        </Col>
        <Col span={8}>
          <div>Quantity / 數量</div>
          <InputNumber
            size="large"
            min={0}
            max={max}
            value={value}
            onChange={onChangeWrapped}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
}

export default ComboItemSelector;
