import React from "react";

import { Alert, Flex, Spin, Typography } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";

import { TRestaurantSlug } from "../constants/o2";
import { RestaurantContext } from "../layouts/Page";
import { convertPrice } from "../utils/Util";
import { LARGE_VOLUME_MIN_LEAD_DAYS } from "../constants/constants";
import { useLargeVolumePickupConfig } from "../hooks/useLargeVolumePickupConfig";

const { Paragraph, Text, Title } = Typography;

export default function LargeVolumeDeliveryRules({
  restaurantSlug,
}: {
  restaurantSlug: TRestaurantSlug;
}) {
  const restaurant = React.useContext(RestaurantContext);
  const config = useLargeVolumePickupConfig({ restaurantSlug });

  if (config == null) {
    return <Spin size="large" />;
  }

  const priceToDisplay: string = convertPrice(config.minimum_pretax_price, {
    dropZero: true,
  });
  return (
    <Flex vertical justify="space-between" style={{ gap: 15 }}>
      <Alert
        type="info"
        message={`Minimum spend / 最低消費: $${priceToDisplay}`}
      />
      {restaurant != null && (
        <Text>
          <EnvironmentOutlined /> Pickup location / 取餐地點:
          <br />
          {restaurant.address ?? restaurant.display_name}
        </Text>
      )}
    </Flex>
  );
}
