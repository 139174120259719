import { LoadingOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Typography } from "antd";
import { useEffect, useState } from "react";

import {
  RESTAURANT_IDS,
  RESTAURANT_NAMES,
  RESTAURANT_SLUGS,
  TRestaurantSlug,
} from "../constants/o2";
import Page from "../layouts/Page";
import { RestaurantPublicInfo } from "../types/model";
import { API_URL, getFetch, getHourInfoStr } from "../utils/Util";
import CustomCard from "../components/CustomCard";
import { useNavigate } from "react-router";

const { Text, Title } = Typography;

export default () => {
  const navigate = useNavigate();
  return (
    <Page>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomCard>
          <Typography.Title level={3}>Contact Us</Typography.Title>
          {RESTAURANT_SLUGS.map((restaurantSlug) => (
            <LocationCard restaurantSlug={restaurantSlug} />
          ))}
          <Button
            type="primary"
            onClick={() => {
              navigate("/");
            }}
            size="large"
          >
            Back to Home
          </Button>
        </CustomCard>
      </div>
    </Page>
  );
};

function LocationCard({ restaurantSlug }: { restaurantSlug: TRestaurantSlug }) {
  const [restaurantInfo, setRestaurantInfo] = useState<
    RestaurantPublicInfo | undefined
  >(undefined);

  useEffect(() => {
    getFetch<RestaurantPublicInfo>(
      `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/info`,
      (res) => {
        if (res) {
          setRestaurantInfo(res);
        }
      },
    );
  }, []);

  const hourInfo: string | undefined = getHourInfoStr(restaurantInfo);
  const addressElem = restaurantInfo ? (
    <Button
      type="link"
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        restaurantInfo.address,
      )}`}
      style={{ whiteSpace: "normal", wordBreak: "break-word" }}
    >
      {restaurantInfo.address}
    </Button>
  ) : (
    <LoadingOutlined />
  );
  const phoneElem = restaurantInfo ? (
    <div style={{ display: "flex" }}>
      <Text type="secondary">
        {RESTAURANT_NAMES[restaurantSlug] === "Cupertino" ? (
          <a href="tel:4085640542">(408) 564-0542</a>
        ) : (
          <a href="tel:6506131700">(650) 613-1700</a>
        )}
      </Text>
    </div>
  ) : (
    <LoadingOutlined />
  );
  const hoursElem = restaurantInfo ? (
    <Text type="secondary">{hourInfo}</Text>
  ) : (
    <LoadingOutlined />
  );

  return (
    <Flex
      vertical
      align="center"
      justify="space-between"
      style={{
        height: "200px",
        border: "3px solid #DEC623",
        borderRadius: 8,
        padding: "1rem",
        backgroundColor: "#DEC62320",
      }}
    >
      <Divider style={{ margin: 0 }}>
        <Title level={5}>{RESTAURANT_NAMES[restaurantSlug]}</Title>
      </Divider>
      {hoursElem}
      {addressElem}
      {phoneElem}
    </Flex>
  );
}
