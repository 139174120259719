import React from "react";
import { useParams } from "react-router-dom";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import { Announcement, GetAnnouncementsResponse } from "../types/api";
import { API_URL, fetchT } from "../utils/Util";

const { Panel } = Collapse;

const announcementColorMapping = {
  INFO: "#7C9D1B",
  WARNING: "#e06710",
  SHUT_DOWN: "#c70e0e",
};

export default () => {
  const { restaurantSlug } = useParams<{
    restaurantSlug: TRestaurantSlug;
  }>();

  const [announcements, setAnnouncements] = React.useState<
    Announcement[] | undefined
  >(undefined);

  const getAnnouncements = () => {
    if (restaurantSlug) {
      fetchT<GetAnnouncementsResponse>({
        method: "GET",
        url: `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/announcements`,
        handleResponse: (res) => {
          setAnnouncements(res.announcements);
        },
        handleError: () => {
          // Do nothing on error - announcements is best effort.
        },
      });
    }
  };

  React.useEffect(getAnnouncements, []);

  if (announcements === undefined) {
    return null;
  }

  return (
    <Collapse bordered={false}>
      {announcements &&
        announcements.map((a: Announcement, idx: number) => {
          return (
            <Panel
              header={
                <span style={{ color: "white", fontSize: 16 }}>
                  <InfoCircleOutlined />
                  <span style={{ marginLeft: 4 }}>{a.title}</span>
                </span>
              }
              key={idx}
              style={{
                border: `1px solid ${
                  announcementColorMapping[a.annoucement_type]
                }`,
                backgroundColor: `${
                  announcementColorMapping[a.annoucement_type]
                }`,
              }}
            >
              <p
                style={{
                  backgroundColor: `${
                    announcementColorMapping[a.annoucement_type]
                  }`,
                  color: "white",
                  whiteSpace: "pre-line", // To preserve line breaks in the announcement content
                }}
              >
                {a.content}
              </p>
            </Panel>
          );
        })}
    </Collapse>
  );
};
