import React, { Fragment, ReactElement, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Divider, Typography } from "antd";
import { ShoppingOutlined } from "@ant-design/icons";

import { RestaurantContext } from "../layouts/Page";
import styles from "../css/Banner.module.css";
import CustomBanner from "./CustomBanner";

const { Paragraph, Text, Title } = Typography;

type DineInBannerProps = {
  // We should always prefer to use table_name_v2.
  // Historically, table_name did not include the prefix (e.g. "5" instead of
  // "Table 5"). table_name_v2 fixes this.
  table_name: string | null;
  table_name_v2: string | null;
};

export default (props: DineInBannerProps) => {
  const restaurant = useContext(RestaurantContext);
  const navigate = useNavigate();

  let menuLink = "/menu";
  if (restaurant?.slug != null) {
    menuLink = `/${restaurant.slug}/menu`;
  }

  let displayTableName = "";
  if (props.table_name_v2 != null) {
    displayTableName = props.table_name_v2;
  } else {
    // Legacy case
    displayTableName = `Table ${props.table_name || ""}`;
  }

  return (
    <CustomBanner>
      <Title
        level={5}
        style={{
          overflowWrap: "break-word",
        }}
      >
        {displayTableName} In Restaurant | 現場點餐
      </Title>
      <Text type="secondary">Not in the restaurant? 不在餐廳裡？</Text>
      <Button type="primary" onClick={() => navigate(menuLink)}>
        Order To Go 預訂外帶
      </Button>
    </CustomBanner>
  );
};
