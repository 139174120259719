import React from "react";

import { Input, Typography } from "antd";

const { Text } = Typography;

// Copied from https://github.com/ant-design/ant-design/blob/340a0a913a44d6cfd65949fb0e14a1caa51a19f2/components/input/Input.tsx#L14
export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "size" | "prefix" | "type"
  > {
  prefixCls?: string;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  allowClear?: boolean;
  bordered?: boolean;

  // Modified by ticketrails to simplify the type.
  size?: "large"; // SizeType
  type?: "tel";
}

// A telephone input field with added authorization message.
// We need the wrapper for the authorization message to be displayed next to the
// input field in a form.
export default (props: InputProps) => {
  return (
    <>
      <Input type="tel" size="large" {...props} />
      <Text type="secondary">
        By providing your phone number, you authorize O2 Valley to send order
        status updates via text messages.
      </Text>
    </>
  );
};
