import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Statistic, Button, FloatButton } from "antd";
import { DownOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import { RestaurantContext } from "../layouts/Page";
import { GetWaitEstimateResponse } from "../types/api";
import { API_URL, getFetch } from "../utils/Util";

type WaitTimeFloaterProps = {
  // Minimum wait time in minutes.
  minWaitMinute?: number;
};

const WaitTimeFloater: React.FC<WaitTimeFloaterProps> = (props) => {
  const restaurant = useContext(RestaurantContext);
  const { restaurantSlug } = useParams<{ restaurantSlug: TRestaurantSlug }>();

  const [waitEstimateMin, setWaitEstimateMin] = useState<number | undefined>(
    props.minWaitMinute,
  );
  const [visible, setVisible] = useState(true);

  const updateWaitEstimate = (itemCount: number) => {
    if (restaurantSlug) {
      getFetch<GetWaitEstimateResponse>(
        `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/wait_estimate?item_count=${itemCount}`,
        (res) => {
          if (res) {
            setWaitEstimateMin(res.seconds / 60);
          }
        },
      );
    }
  };

  useEffect(() => {
    // Give an estimated wait time based on ordering 3 items.
    // TODO: use average number.
    updateWaitEstimate(3);
  }, [restaurantSlug]);

  // If lacking restaurant info, don't show.
  if (restaurant === undefined) {
    return null;
  }

  return (
    <>
      {visible && (
        <div
          style={{
            position: "fixed",
            bottom: "2rem",
            left: "1rem",
            zIndex: 1000,
            background: "#FFFFFF",
            padding: "10px",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          }}
        >
          {waitEstimateMin === undefined ? (
            <div />
          ) : (
            <Statistic title="Wait Time" value={waitEstimateMin} suffix="min" />
          )}
          <Button
            type="primary"
            icon={<DownOutlined />}
            onClick={() => setVisible(false)}
          />
        </div>
      )}
      {!visible && (
        <FloatButton
          shape="square"
          icon={<FieldTimeOutlined color="#2D211F" />}
          type="primary"
          style={{ bottom: "2rem", left: "1rem" }}
          onClick={() => setVisible(true)}
        />
      )}
    </>
  );
};

export default WaitTimeFloater;
