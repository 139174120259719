import React from "react";

import { Input, Select, Space } from "antd";

import { Address } from "../types/model";

const { Option } = Select;

type AddressInputProps = {
  value?: Address;
  onChange?: (value: Address) => void;

  // If set, restrict address input to California only.
  caOnly?: boolean;
};

export default function AddressInput({
  value,
  onChange,
  caOnly,
}: AddressInputProps) {
  const [addr, setAddr] = React.useState<Address>(
    value || {
      line1: "",
      city: "",
      state: caOnly ? "CA" : "",
      postal_code: "",
    },
  );
  React.useEffect(() => {
    if (onChange) {
      onChange(addr);
    }
  }, [addr]);

  React.useEffect(() => {
    if (caOnly) {
      setAddr((prev) => {
        const n = { ...prev };
        n.state = "CA";
        return n;
      });
    }
  }, [caOnly]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Input
        size="large"
        type="text"
        placeholder="Line 1"
        value={addr.line1}
        style={{ width: "100%" }}
        onChange={(ev) => {
          setAddr((prev) => {
            const n = { ...prev };
            n.line1 = ev?.target?.value || "";
            return n;
          });
        }}
      />
      <Input
        size="large"
        type="text"
        placeholder="Line 2 (optional)"
        value={addr.line2 || ""}
        style={{ width: "100%" }}
        onChange={(ev) => {
          setAddr((prev) => {
            const n = { ...prev };
            n.line2 = ev?.target?.value || "";
            return n;
          });
        }}
      />
      <div style={{ width: "100%" }}>
        <Input
          size="large"
          type="text"
          placeholder="City"
          value={addr.city}
          style={{ width: "70%" }}
          onChange={(ev) => {
            setAddr((prev) => {
              const n = { ...prev };
              n.city = ev?.target?.value || "";
              return n;
            });
          }}
        />
        <Input
          size="large"
          type="text"
          placeholder="ZIP Code"
          value={addr.postal_code}
          style={{ marginLeft: "2%", width: "28%" }}
          maxLength={5}
          onChange={(ev) => {
            setAddr((prev) => {
              const n = { ...prev };
              n.postal_code = ev?.target?.value || "";
              return n;
            });
          }}
        />
      </div>
      <Select
        showSearch
        placeholder="State"
        value={addr.state}
        size="large"
        filterOption={(input, option) => {
          const key = option?.key.toLowerCase();
          if (key == null) {
            return false;
          }
          return key.indexOf(input.toLowerCase()) >= 0;
        }}
        style={{ width: "100%" }}
        onChange={(v: string) => {
          setAddr((prev) => {
            const n = { ...prev };
            n.state = v;
            return n;
          });
        }}
        disabled={caOnly}
      >
        {caOnly ? (
          <Option key={`state-fixed`} value={"CA"}>
            California
          </Option>
        ) : (
          Object.keys(states).map((k) => (
            <Option key={`state-${k}`} value={states[k]}>
              {k}
            </Option>
          ))
        )}
      </Select>
    </Space>
  );
}

const states: { [key: string]: string } = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};
