import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Alert, Button, Divider, Form, Input, Typography } from "antd";
import {
  FormOutlined,
  LoginOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import Page from "../layouts/Page";
import { CustomerCredentials, LogIn, GetLogInState } from "../utils/Auth";
import { NotifyError } from "../utils/NotifyError";
import { useQuery } from "../hooks/useQuery";
import CustomCard from "../components/CustomCard";
import { TRestaurantSlug } from "../constants/o2";

type LocationState = {
  from: string;
};
const { Text, Title } = Typography;

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { restaurantSlug } = useParams<{ restaurantSlug: TRestaurantSlug }>();

  const loginRedirect = () => {
    const fromPathname = location.state?.from?.pathname;
    navigate(
      {
        pathname: fromPathname ?? "/",
        search: query.toString(),
      },
      { replace: true },
    );
  };

  const onFinish = (creds: CustomerCredentials) => {
    LogIn(creds, (err: Error | null) => {
      if (err) {
        NotifyError("Login failed", err);
      } else {
        loginRedirect();
      }
    });
  };

  const unsupported = GetLogInState() === "UNSUPPORTED";

  const formElem = (
    <Form labelAlign="left" layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Email 電⼦郵箱 or Phone 電話"
        name="email_or_phone"
        rules={[
          {
            required: true,
            message: "Please enter your email or phone number!",
          },
          {
            // RFC 3696 with errata limits email to 254 characters
            message: "Email cannot exceed 256 characters.",
            type: "string",
            max: 256,
          },
        ]}
      >
        <Input
          placeholder="example@email.com"
          disabled={unsupported}
          size="large"
        />
      </Form.Item>

      <Form.Item
        label="Password 密碼"
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter your password!",
          },
          {
            message: "Password cannot exceed 512 characters.",
            type: "string",
            max: 512,
          },
        ]}
      >
        <Input
          type="password"
          placeholder="Enter Password"
          disabled={unsupported}
          size="large"
        />
      </Form.Item>
      <Button
        type="link"
        onClick={() =>
          navigate(`/forgot_password?${query.toString()}`, {
            state: location.state,
          })
        }
        icon={<QuestionCircleOutlined />}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "16px 0",
          padding: "2px 0",
        }}
      >
        Forgot Password / 忘記密碼
      </Button>
      <Form.Item>
        <Button
          type="primary"
          block
          size="large"
          htmlType="submit"
          disabled={unsupported}
        >
          <LoginOutlined /> Log In / 登入
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Page>
      <CustomCard>
        <Title level={3}>Welcome</Title>
        {unsupported && (
          <Alert
            type="error"
            showIcon
            message="Unsupported browser / 不支援的瀏覽器"
            description="Your browser does not support log in feature. You may continue to use the website without logging in. 瀏覽器不支援登入，您可以繼續使用無須登入的功能。"
            style={{ margin: "1rem 0" }}
          />
        )}
        {formElem}
        <Divider style={{ margin: 0 }}>or</Divider>
        <Text>Not a member?</Text>
        <Button
          type="primary"
          block
          size="large"
          disabled={unsupported}
          onClick={() =>
            navigate(
              {
                pathname: `/${restaurantSlug}/signup`,
                search: query.toString(),
              },
              { state: location.state },
            )
          }
        >
          <FormOutlined /> Sign Up / 註冊
        </Button>
      </CustomCard>
    </Page>
  );
};

export default LoginPage;
