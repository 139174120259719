import { notification } from "antd";
import * as Sentry from "@sentry/react";

import { UserInputError } from "./errors";

export function NotifyError(title: string, err: Error) {
  const { apiStatusCode, description } = ExtractErrorDetail(err);

  if (process.env.NODE_ENV === "production") {
    if (apiStatusCode == null && !(err instanceof UserInputError)) {
      // Lack of status code indicates that the error is not from backend.
      // Report this to sentry.
      Sentry.captureException(err);
    }
  }

  notification["error"]({ message: title, description });
}

type ErrorDetail = {
  description: string;
  apiStatusCode?: number;
};

export function ExtractErrorDetail(err: Error): ErrorDetail {
  try {
    const obj = JSON.parse(err.message);
    return {
      description: obj["message"] ?? err.toString(),
      apiStatusCode: obj["status_code"] ?? undefined,
    };
  } catch (e) {
    // Got a non-api error maybe?
    return { description: err.toString() };
  }
}
