import React from "react";

import { Alert, Button, Flex, Input, Modal, Spin, Typography } from "antd";
import {
  CloseOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { TRestaurantSlug } from "../constants/o2";
import { useLargeVolumeDeliveryZoneInfo } from "../hooks/useLargeVolumeDeliveryZoneInfo";
import { convertPrice } from "../utils/Util";
import { LARGE_VOLUME_MIN_LEAD_DAYS } from "../constants/constants";
import { GetLargeVolumeDeliveryZonesResponse } from "../types/api";
import { Link, useNavigate } from "react-router-dom";

const { Paragraph, Text, Title } = Typography;

export function LargeVolumeDeliveryRulesDialog({
  restaurantSlug,
}: {
  restaurantSlug: TRestaurantSlug;
}) {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  return (
    <>
      <Button
        type="primary"
        onClick={() => setShowDialog(true)}
        size="large"
        icon={<InfoCircleOutlined />}
      >
        Delivery Requirements / 外送標準
      </Button>
      <Modal
        title="Delivery Requirements / 外送標準"
        open={showDialog}
        onCancel={() => setShowDialog(false)}
        footer={null}
      >
        <LargeVolumeDeliveryRules restaurantSlug={restaurantSlug} />
      </Modal>
    </>
  );
}

export default function LargeVolumeDeliveryRules({
  restaurantSlug,
}: {
  restaurantSlug: TRestaurantSlug;
}) {
  const navigate = useNavigate();
  const [eligible, setEligible] = React.useState<boolean>(false);
  const [zipcode, setZipcode] = React.useState<number | undefined>(undefined);
  const zoneInfo = useLargeVolumeDeliveryZoneInfo({ restaurantSlug });

  const onChange = (newValue: string) => {
    const n = parseInt(newValue);
    if (isNaN(n) || 10000 > n || n > 99999) {
      setEligible(false);
      setZipcode(undefined);
    } else {
      setZipcode(n);
    }
  };

  return (
    <Flex vertical justify="space-between" style={{ gap: 15 }}>
      <Title level={5}>
        Eligibility and Delivery Fee <br /> 服務範圍及外送費用
      </Title>
      <Text>Please enter your zip code / 請輸入您的郵遞區號：</Text>
      <Input
        size="large"
        maxLength={5}
        placeholder="Enter your zip code / 請輸入您的郵遞區號"
        onChange={(e) => onChange(e.target.value)}
      />
      {zipcode !== undefined && (
        <DeliveryFeeTable
          restaurantSlug={restaurantSlug}
          zipcode={zipcode}
          zoneInfo={zoneInfo}
          onEligibilityChange={setEligible}
        />
      )}
      {zipcode !== undefined && eligible && (
        <Button
          size="large"
          type="primary"
          disabled={!eligible}
          onClick={() => navigate(`/${restaurantSlug}/group/delivery`)}
        >
          Order Now / 立即訂購
        </Button>
      )}
      {zipcode !== undefined && !eligible && (
        <Button
          size="large"
          type="primary"
          onClick={() => navigate(`/contact`)}
        >
          Contact Us
        </Button>
      )}
    </Flex>
  );
}

type DeliveryFeeRules = {
  minimumSpend: number;
  deliveryFeeWaivedThreshold: number;
  deliveryFee: number;
};

function DeliveryFeeTable({
  restaurantSlug,
  zipcode,
  zoneInfo,
  onEligibilityChange,
}: {
  restaurantSlug: TRestaurantSlug;
  zipcode: number;
  zoneInfo: GetLargeVolumeDeliveryZonesResponse | null;
  onEligibilityChange?: (eligible: boolean) => void;
}) {
  if (zoneInfo == null) {
    if (onEligibilityChange != null) {
      onEligibilityChange(false);
    }
    return <Spin size="large" />;
  }

  const zone = zoneInfo.zones_by_postal_code[zipcode.toString()];

  const isEligible = zone != null;
  if (onEligibilityChange != null) {
    onEligibilityChange(isEligible);
  }

  return isEligible ? (
    <>
      <Alert
        showIcon
        type="success"
        message={`${zipcode} is elgibile for delivery! 我們可以外送至 ${zipcode}!`}
        style={{ textAlign: "left" }}
      />
      <Text>
        Minimum Purchase / 最低消費: $
        {convertPrice(zone.minimum_pretax_price, { dropZero: true })}
      </Text>
      <Text>
        Delivery Fee / 外送費用: $
        {convertPrice(zone.delivery_fee, { dropZero: true })}
      </Text>
      <Alert
        type="info"
        message={`FREE Delivery for orders over $${convertPrice(zone.free_delivery_threshold, { dropZero: true })}
					消費滿 $${convertPrice(zone.free_delivery_threshold, { dropZero: true })} 免外送費`}
      />
    </>
  ) : (
    <Alert
      type="error"
      message={`Sorry, ${zipcode} is not eligible for orders placed online. 網路訂單無法外送至 ${zipcode}`}
      description="Please contact us for your group order. 請與我們聯繫"
    />
  );
}
