import React from "react";
import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import { API_URL, fetchT } from "../utils/Util";
import { LargeVolumePickupConfig } from "../types/api";

// A custom hook to fetch delivery zone info for large volume delivery orders.
export const useLargeVolumePickupConfig = ({
  restaurantSlug,
}: {
  restaurantSlug: TRestaurantSlug;
}): LargeVolumePickupConfig | null => {
  const [resp, setResp] = React.useState<LargeVolumePickupConfig | null>(null);

  React.useEffect(() => {
    fetchT<LargeVolumePickupConfig>({
      method: "GET",
      url: `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/large_volume/pickup_config`,
      handleResponse: setResp,
      handleError: (err: Error) => {
        console.log("failed to get pickup config", err);
        // Just keep the hook's value as null instead of erroring.
        // This is important because we currently try to fetch delivery zone
        // for all restaurants on menu render, regardless of whether they
        // support delivery orders.
      },
    });
  }, [restaurantSlug]);

  return resp;
};
