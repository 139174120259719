import React from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { TruckOutlined } from "@ant-design/icons";
import { Button, Collapse, Typography, Alert, Divider } from "antd";
import {
  CaretRightOutlined,
  TeamOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

import { TRestaurantSlug } from "../constants/o2";
import Page from "../layouts/Page";
import LargeVolumeDeliveryRules from "../components/LargeVolumeDeliveryRules";
import LargeVolumePickupRules from "../components/LargeVolumePickupRules";

import CustomCard from "../components/CustomCard";
import { LARGE_VOLUME_MIN_LEAD_DAYS } from "../constants/constants";

const { Panel } = Collapse;
const { Title } = Typography;

export default () => {
  return (
    <Page>
      <LargeVolumeHomeInner />
    </Page>
  );
};

function LargeVolumeHomeInner() {
  const navigate = useNavigate();
  const { restaurantSlug } = useParams<{
    restaurantSlug: TRestaurantSlug;
  }>();
  if (!restaurantSlug) {
    return <div>Error: Restaurant slug is missing</div>;
  }

  return (
    <CustomCard>
      <Title level={4}>
        <TeamOutlined /> Group Order / 團購
      </Title>
      <Alert
        type="info"
        message={`Orders must be placed ${LARGE_VOLUME_MIN_LEAD_DAYS} days in advance. 必須於 ${LARGE_VOLUME_MIN_LEAD_DAYS} 天前下單`}
      />
      <Collapse
        bordered={false}
        accordion={true}
        defaultActiveKey={[]}
        style={{
          background: "#f7f7f7",
          borderRadius: "8px",
          border: "0px",
        }}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={<DeliveryHeader />} key="delivery">
          <DeliveryBody restaurantSlug={restaurantSlug} />
        </Panel>
        <Panel header={<PickupHeader />} key="pickup">
          <PickupBody restaurantSlug={restaurantSlug} />
        </Panel>
      </Collapse>
      <Divider style={{ margin: 0 }}>or</Divider>
      <Button
        type="primary"
        onClick={() => {
          navigate(-1);
        }}
        size="large"
      >
        Back to Previous Page
      </Button>
    </CustomCard>
  );
}

function DeliveryHeader() {
  return (
    <Title level={5}>
      <TruckOutlined /> Delivery / 外送
    </Title>
  );
}

function PickupHeader() {
  //Pickup / 自取
  return (
    <Title level={5}>
      <ShoppingOutlined /> Pickup / 自取
    </Title>
  );
}

function DeliveryBody({ restaurantSlug }: { restaurantSlug: TRestaurantSlug }) {
  return <LargeVolumeDeliveryRules restaurantSlug={restaurantSlug} />;
}

function PickupBody({ restaurantSlug }: { restaurantSlug: TRestaurantSlug }) {
  return (
    <>
      <LargeVolumePickupRules restaurantSlug={restaurantSlug} />
      <Link to={`/${restaurantSlug}/group/pickup`}>
        <Button
          size="large"
          type="primary"
          style={{ width: "100%", marginTop: "16px" }}
        >
          Order Now / 立即訂購
        </Button>
      </Link>
    </>
  );
}
