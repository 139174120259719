import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Result } from "antd";
import qs from "qs";

import { ExtractErrorDetail, NotifyError } from "../utils/NotifyError";
import { DEFAULT_RESTAURANT_SLUG, RESTAURANT_IDS } from "../constants/o2";
import Page from "../layouts/Page";
import { PasswordResetRequest, PasswordResetResponse } from "../types/api";
import { API_URL, fetchT } from "../utils/Util";
import { UserInputError } from "../utils/errors";
import CustomCard from "../components/CustomCard";

const PasswordReset: React.FC = () => {
  const { tokenID } = useParams<{ tokenID?: string }>();
  const location = useLocation();

  const rawSecret = qs.parse(location.search, { ignoreQueryPrefix: true }).s;
  const secret: string = rawSecret as string;

  const [resetDone, setResetDone] = useState<boolean>(false);
  const [resetErr, setResetErr] = useState<Error | undefined>(undefined);

  const onFinish = (
    req: PasswordResetRequest & { confirm_new_password: string },
  ) => {
    if (req.new_password !== req.confirm_new_password) {
      NotifyError(
        "Please try again",
        new UserInputError("new passwords do not match"),
      );
      return;
    }

    fetchT<PasswordResetResponse>({
      method: "POST",
      url: `${API_URL}/v1/public/r/${RESTAURANT_IDS[DEFAULT_RESTAURANT_SLUG]}/password_reset`,
      body: req,
      handleResponse: () => {
        setResetDone(true);
      },
      handleError: (err: Error) => {
        setResetDone(true);
        setResetErr(err);
      },
    });
  };

  if (!tokenID) {
    return (
      <Page>
        <Result
          status="error"
          title="Invalid Token"
          subTitle="The password reset token is missing or invalid."
          extra={
            <Link to="/">
              <Button size="large">Back to Homepage</Button>
            </Link>
          }
        />
      </Page>
    );
  }

  if (resetDone) {
    if (resetErr) {
      return (
        <Page>
          <Result
            status="error"
            title="Password reset failed."
            subTitle={ExtractErrorDetail(resetErr).description}
            extra={
              <Link to="/">
                <Button size="large">Back to Homepage</Button>
              </Link>
            }
          />
        </Page>
      );
    }

    return (
      <Page>
        <Result
          status="success"
          title="Your password has been reset."
          extra={
            <Link to="/login">
              <Button size="large">Log In Now / 登入</Button>
            </Link>
          }
        />
      </Page>
    );
  }

  const initial_req: PasswordResetRequest = {
    token_id: tokenID,
    secret: secret,
    new_password: "",
  };

  const formElem = (
    <Form onFinish={onFinish} initialValues={initial_req}>
      <Form.Item name="token_id" hidden={true} />
      <Form.Item name="secret" hidden={true} />
      <Form.Item
        name="new_password"
        rules={[
          {
            required: true,
            message: "Please enter your new password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="New Password / 新密碼"
        />
      </Form.Item>
      <Form.Item
        name="confirm_new_password"
        rules={[
          {
            required: true,
            message: "Please confirm your new password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Confirm New Password / 確認新密碼"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" block size="large" htmlType="submit">
          Reset my Password / 重設密碼
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Page>
      <CustomCard>{formElem}</CustomCard>
    </Page>
  );
};

export default PasswordReset;
