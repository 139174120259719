import React, { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Divider,
  Form,
  Result,
  Spin,
  Typography,
  notification,
} from "antd";

import Page from "../layouts/Page";
import { GetBasicAuth } from "../utils/Auth";
import { ExtractErrorDetail, NotifyError } from "../utils/NotifyError";
import { API_URL, fetchT } from "../utils/Util";
import { DEFAULT_RESTAURANT_SLUG, RESTAURANT_IDS } from "../constants/o2";
import { CustomerSettings, UpdateCustomerSettingsRequest } from "../types/api";
import { useNavigate } from "react-router";
import CustomCard from "../components/CustomCard";

const { Title, Text } = Typography;

// Must be visited by a logged in customer to edit their settings.
export default () => {
  const navigate = useNavigate();
  const [settings, setSettings] = React.useState<CustomerSettings | null>(null);
  const [err, setErr] = useState<Error | undefined>(undefined);

  const updateInfo = () => {
    setSettings(null);

    fetchT<CustomerSettings>({
      method: "GET",
      url: `${API_URL}/v1/customer/r/${RESTAURANT_IDS[DEFAULT_RESTAURANT_SLUG]}/settings`,
      auth: auth ?? "",
      handleResponse: setSettings,
      handleError: setErr,
    });
  };

  const auth = GetBasicAuth();
  if (auth == null) {
    // This should never happen since the router does not allow access unless
    // logged in.
    return <div>Please log in first.</div>;
  } else {
    useEffect(updateInfo, []);
  }

  let content: React.ReactElement;
  if (err !== undefined) {
    content = (
      <Result
        status="error"
        title="Failed to customer information"
        subTitle={ExtractErrorDetail(err).description}
        extra={
          <Button
            type="primary"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Back to Homepage
          </Button>
        }
      />
    );
  } else if (settings != null) {
    const onFinish = (req: UpdateCustomerSettingsRequest) => {
      fetchT({
        method: "POST",
        url: `${API_URL}/v1/customer/r/${RESTAURANT_IDS[DEFAULT_RESTAURANT_SLUG]}/settings`,
        auth: auth,
        body: req,
        handleResponse: () => {
          notification["success"]({
            message: "Success!",
            description: "You have successfully updated your settings!",
          });

          updateInfo();
        },
        handleError: (err: Error) => {
          NotifyError("Sign Up Failed", err);
        },
      });
    };

    content = (
      <Form
        initialValues={{ allow_marketing: settings.allow_marketing ?? false }}
        onFinish={onFinish}
      >
        <Form.Item name="allow_marketing" valuePropName="checked">
          <Checkbox style={{ textAlign: "left" }}>
            Receive promotions from O2 Valley (Less than 1 per month)
            <br />
            <Text type="secondary">Message and data rates may apply</Text>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            Save / 儲存
          </Button>
        </Form.Item>
      </Form>
    );
  } else {
    // Loading
    content = <Spin />;
  }

  return (
    <Page>
      <CustomCard>
        <Title level={4}>Settings / 設定</Title>
        {content}
        <Divider style={{ margin: 0 }}>or</Divider>
        <Button
          type="primary"
          block
          size="large"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to Previous Page
        </Button>
      </CustomCard>
    </Page>
  );
};
