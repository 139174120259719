// Unified time display format for user-friendly display.
// Mon, Jan 18, 05:06 PM
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Extend dayjs with the required plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Format time
export const dateFmt = "ddd, MMM DD";
export const timeFmt = "hh:mm";
export const timeDateFmt = "ddd, MMM DD, hh:mm A";

export function formatTimeString(ts: string | undefined): string {
  if (!ts) {
    return "";
  }
  return dayjs(ts).tz(dayjs.tz.guess()).format(timeDateFmt);
}

export function formatDateString(ts: string | undefined): string {
  if (!ts) {
    return "";
  }
  return dayjs(ts).tz(dayjs.tz.guess()).format("YYYY/MM/DD");
}
