import { Anchor, Col, Divider, Result, Row } from "antd";
import { Element } from "react-scroll";
import { FrownOutlined } from "@ant-design/icons";

import MenuItem from "./MenuItem";
import { PaidLineItem, RedeemedLineItem } from "../types/ui";
import { CustomerInfo, GetMenuResponse } from "../types/api";
import { FulfillmentType, ItemSchema, ItemSchemaGroup } from "../types/model";

export type MenuScrollProps = {
  customer: CustomerInfo | null;
  menu: GetMenuResponse;
  pointsInCart: number;
  fulfillmentType: FulfillmentType;
  onAddToCart: (lineItem: PaidLineItem | RedeemedLineItem) => void;
  groupMenuSlug?: string;
};

export function MenuScroll(props: MenuScrollProps) {
  const { menu, fulfillmentType, groupMenuSlug } = props;

  if (menu.group_menu_info) {
    const deadline = new Date(menu.group_menu_info.order_deadline);
    const now = new Date();
    if (now > deadline) {
      return (
        <Result
          icon={<FrownOutlined />}
          title={"The order deadline for this group menu has passed!"}
        />
      );
    }
  }

  const groupKey = (group: { id: string }) => {
    if (groupMenuSlug !== undefined) {
      return `${fulfillmentType}_${groupMenuSlug}_${group.id}`;
    }
    return `${fulfillmentType}_${group.id}`;
  };

  const groupsToUse = menu.item_schema_groups.filter(
    (g) => g.item_schemas.length > 0,
  );

  // Create an artificial group for featured items.
  const featuredItems: Array<ItemSchema> = [];
  for (const group of menu.item_schema_groups) {
    featuredItems.push(...group.item_schemas.filter((is) => is.featured));
  }
  if (featuredItems.length > 0) {
    groupsToUse.unshift({
      id: "isg_featured_items",
      name: "Featured / 推薦",
      item_schemas: featuredItems,
    });
  }

  return (
    <div>
      <ScrollHeader
        destinations={groupsToUse.map((g) => ({
          key: groupKey(g),
          label: g.name,
        }))}
      />
      {groupsToUse.map((group) => {
        const key = groupKey(group);
        return (
          <Element name={key} key={key}>
            <ItemSchemaGroupDisplay
              groupKey={key}
              group={group}
              props={props}
            />
          </Element>
        );
      })}
    </div>
  );
}

function ItemSchemaGroupDisplay({
  groupKey,
  group,
  props,
}: {
  groupKey: string;
  group: ItemSchemaGroup;
  props: MenuScrollProps;
}) {
  return (
    <div id={groupKey} style={{ padding: "16px" }}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Divider style={{ margin: 0 }}>{group.name}</Divider>
        </Col>
        {group.item_schemas.map((item) => {
          const key = `${groupKey}_${item.id}`;
          return (
            <Col xs={24} sm={12} md={8} lg={6} xl={6} key={key}>
              <div style={{ height: "100%" }}>
                <MenuItem
                  customer={props.customer}
                  pointsInCart={props.pointsInCart}
                  item={item}
                  onAddToCart={props.onAddToCart}
                  fulfillmentType={props.fulfillmentType}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

type ScrollDestination = {
  key: string;
  label: string;
};

function ScrollHeader({
  destinations,
}: {
  destinations: ReadonlyArray<ScrollDestination>;
}) {
  return (
    <Anchor
      direction="horizontal"
      offsetTop={100}
      items={destinations.map((d) => ({
        key: d.key,
        title: d.label,
        href: `#${d.key}`,
      }))}
      style={{ padding: "1rem", backgroundColor: "#FFFFFF" }}
    />
  );
}
