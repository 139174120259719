import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Result, Spin } from "antd";

import { GetBasicAuth } from "../utils/Auth";
import { ExtractErrorDetail } from "../utils/NotifyError";
import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import Page from "../layouts/Page";
import { ClaimPointsResponse } from "../types/api";
import { API_URL, fetchT } from "../utils/Util";

// Must be visited by a logged in customer to claim points from a purchase
// by visiting the unique URL that we generate.
export default () => {
  const { creditID, restaurantSlug } = useParams<{
    creditID: string;
    restaurantSlug: TRestaurantSlug;
  }>();

  const [resp, setResp] = useState<ClaimPointsResponse | null>(null);
  const [err, setErr] = useState<Error | null>(null);

  const auth = GetBasicAuth();

  useEffect(() => {
    if (auth != null && restaurantSlug) {
      fetchT<ClaimPointsResponse>({
        method: "POST",
        url: `${API_URL}/v1/customer/r/${RESTAURANT_IDS[restaurantSlug]}/claims/${creditID}`,
        auth: auth,
        handleResponse: setResp,
        handleError: setErr,
      });
    }
  }, [auth]);

  if (auth === null) {
    // This should never happen since the router does not allow access unless
    // logged in.
    return <div>Please log in first.</div>;
  }

  let content: React.ReactElement;
  if (err !== null) {
    content = (
      <Result
        status="error"
        title="Failed to Redeem Points"
        subTitle={ExtractErrorDetail(err).description}
        extra={
          <Button
            type="primary"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Back to Homepage
          </Button>
        }
      />
    );
  } else if (resp !== null) {
    // TODO(kku): current balance.
    content = (
      <Result
        status="success"
        title={`You earned ${resp.claimed} points!`}
        subTitle={
          resp.new_balance ? `Current point balance: ${resp.new_balance}` : ""
        }
        extra={
          <Button
            type="primary"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Back to Homepage
          </Button>
        }
      />
    );
  } else {
    // Loading
    content = <Spin />;
  }

  return <Page>{content}</Page>;
};
