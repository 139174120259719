import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";
import storageAvailable from "storage-available";

import { DEFAULT_RESTAURANT_SLUG, RESTAURANT_IDS } from "../constants/o2";
import { CustomerInfo } from "../types/api";
import { API_URL, fetchT } from "./Util";

const session_key_email_or_phone = "ticketrails_customer_email_or_phone";
const session_key_password = "ticketrails_customer_password";
const session_key_customer_id = "ticketrails_customer_id";

export type CustomerCredentials = {
  email_or_phone: string;
  password: string;
};

// Only used by sign up and this package to store credentials for logged in
// customer.
export function SetCredentials(id: string, creds: CustomerCredentials) {
  localStorage?.setItem(session_key_email_or_phone, creds.email_or_phone);
  localStorage?.setItem(session_key_password, creds.password);
  localStorage?.setItem(session_key_customer_id, id);
}

export function LogIn(
  creds: CustomerCredentials,
  callback: (err: Error | null) => void,
) {
  fetchT<CustomerInfo>({
    method: "GET",
    url: `${API_URL}/v1/customer/r/${RESTAURANT_IDS[DEFAULT_RESTAURANT_SLUG]}/info`,
    auth: btoa(`${creds.email_or_phone}:${creds.password}`),
    handleResponse: (res: CustomerInfo) => {
      SetCredentials(res.id, creds);
      callback(null);
    },
    handleError: callback,
  });
}

export function LogOut() {
  localStorage?.removeItem(session_key_email_or_phone);
  localStorage?.removeItem(session_key_password);
  localStorage?.removeItem(session_key_customer_id);
}

// In some webview, localStorage is disabled. So until we switch to session
// based logins we need to disable login when localStorage is not available.
export type LogInState = "NOT_LOGGED_IN" | "LOGGED_IN" | "UNSUPPORTED";

const clientUuid = uuidv4();

export function GetLogInState(): LogInState {
  if (!storageAvailable("localStorage")) {
    if (process.env.NODE_ENV === "production") {
      Sentry.captureMessage(
        "[TicketRails] detected browser without localStorage support",
        {
          tags: { clientUuid },
        },
      );
    }
    return "UNSUPPORTED";
  }
  if (GetBasicAuth() === null) {
    return "NOT_LOGGED_IN";
  }
  return "LOGGED_IN";
}

export function GetBasicAuth(): string | null {
  const emailOrPhone = localStorage?.getItem(session_key_email_or_phone);
  const password = localStorage?.getItem(session_key_password);
  if (emailOrPhone && password) {
    return btoa(`${emailOrPhone}:${password}`);
  }
  return null;
}

// HACK HACK HACK: check if the user that is currently logged in is an employee.
// If so, allow them to make post pay orders (e.g. for phone orders).
// Note: an attacker can change local storage manually to spoof their customer
// ID, so on the backend we retrieve the customer ID based on log in
// crendentials.
export function IsEmployeeUser(): boolean {
  const customerID = localStorage?.getItem(session_key_customer_id);
  if (customerID) {
    return customerID === "cus_MSQnqKuYSDazDOTnZQcwqA";
  }
  return false;
}
