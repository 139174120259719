import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";

import { CartScope, LineItem } from "../types/ui";
import { assertNever } from "../utils/Util";

export type CartObject = {
  lineItems: Record<string, LineItem>;
};

interface CartState {
  // Use Record type since Map cannot be serialized by persist layer.
  cartsByScope: Record<string, CartObject>;

  addLineItem: (scope: CartScope, item: LineItem) => void;
  removeLineItem: (
    scope: CartScope,
    item: Exclude<LineItem, "quantity">,
  ) => void;
  updateLineItemQty: (
    scope: CartScope,
    item: Exclude<LineItem, "quantity">,
    qty: number,
  ) => void;
  getCartByScope: (scope: CartScope) => Readonly<CartObject>;

  removeAllRedeemedItems: () => void;
  clearScope: (scope: CartScope) => void;
}

function CartStoreImpl(
  set: (f: (state: CartState) => CartState) => void,
  get: () => CartState,
): CartState {
  return {
    cartsByScope: {},

    clearScope: (scope: CartScope) => {
      set(
        produce((state) => {
          delete state.cartsByScope[cartScopeKey(scope)];
        }),
      );
    },

    addLineItem: (scope: CartScope, item: LineItem) => {
      set(
        produce((state) => {
          if (item.quantity <= 0) {
            return;
          }

          const items = getOrCreateScopedCart(
            state.cartsByScope,
            scope,
          ).lineItems;
          const itemKey = lineItemKey(item);
          const existing = items[itemKey];
          if (existing == null) {
            items[itemKey] = item;
          } else {
            existing.quantity += item.quantity;
          }
        }),
      );
    },

    updateLineItemQty: (
      scope: CartScope,
      item: Exclude<LineItem, "quantity">,
      qty: number,
    ) => {
      set(
        produce((state) => {
          const items = getOrCreateScopedCart(
            state.cartsByScope,
            scope,
          ).lineItems;
          const key = lineItemKey(item);

          const existing = items[key];
          if (existing == null) {
            items[key] = { ...item, quantity: qty };
          } else {
            existing.quantity = qty;
          }
        }),
      );
    },

    removeLineItem: (scope: CartScope, item: Exclude<LineItem, "quantity">) => {
      set(
        produce((state) => {
          const items = getOrCreateScopedCart(
            state.cartsByScope,
            scope,
          ).lineItems;
          const key = lineItemKey(item);
          delete items[key];
        }),
      );
    },

    getCartByScope: (scope: CartScope): Readonly<CartObject> => {
      const state = get();
      const key = cartScopeKey(scope);
      return state.cartsByScope[key] ?? emptyCart();
    },

    removeAllRedeemedItems: () => {
      set(
        produce((state) => {
          for (const [_, cart] of Object.entries(state.cartsByScope)) {
            for (const [k, item] of Object.entries(cart.lineItems)) {
              switch (item.item_type) {
                case "paid":
                  // Do nothing
                  break;
                case "redeemed":
                  delete cart.lineItems[k];
                  break;
                default:
                  assertNever(item);
              }
            }
          }
        }),
      );
    },
  };
}

export const useCartStore = create<CartState>()(
  devtools(
    persist(CartStoreImpl, {
      name: "cart",
      version: 0,
      storage: createJSONStorage(() => sessionStorage),
    }),
  ),
);

function emptyCart(): CartObject {
  return { lineItems: {} };
}

function getOrCreateScopedCart(
  cartsByScope: Record<string, CartObject>,
  scope: CartScope,
): CartObject {
  const key = cartScopeKey(scope);
  if (cartsByScope[key] == null) {
    const newCart = emptyCart();
    cartsByScope[key] = newCart;
    return newCart;
  }
  return cartsByScope[key];
}

function cartScopeKey(scope: CartScope): string {
  return `${scope.restaurantSlug}:${scope.fulfillmentType}:${
    scope.groupMenuSlug ?? ""
  }:cart`;
}

function lineItemKey(lineItem: Exclude<LineItem, "quantity">): string {
  const modification_ids: string[] = lineItem.modifications.map(
    (mod) => mod.id,
  );
  modification_ids.sort();

  const combo_choice_keys: string[] = Object.entries(
    lineItem.combo_choices,
  ).map((c) => `${c[0]}-${c[1].quantity}`);
  combo_choice_keys.sort();

  // Standardize no notes to empty string so we can create stable
  // lineItemKey below.
  const notes = lineItem.notes ?? "";

  switch (lineItem.item_type) {
    case "paid":
      return (
        "paid-" +
        JSON.stringify({
          item_schema_id: lineItem.item_schema.id,
          item_modification_ids: modification_ids,
          combo_choice_keys,
          notes,
          price: lineItem.price,
        })
      );
    case "redeemed":
      return (
        "redeemed-" +
        JSON.stringify({
          item_schema_id: lineItem.item_schema.id,
          item_modification_ids: modification_ids,
          combo_choice_keys,
          notes,
          points: lineItem.points,
        })
      );
    default:
      assertNever(lineItem);
  }
}
