import React, { useEffect, useState } from "react";

import { Button, Divider, Result, Spin, Statistic, Table, Tabs } from "antd";
import moment from "moment";

import { GetBasicAuth } from "../utils/Auth";
import { ExtractErrorDetail } from "../utils/NotifyError";
import { PointCredit, PointDebit, PointHistory } from "../types/api";
import { API_URL, fetchT } from "../utils/Util";
import { useNavigate } from "react-router";

const { TabPane } = Tabs;

// Must be visited by a logged in customer to claim points from a purchase
// by visiting the unique URL that we generate.
export default ({ restaurantId }: { restaurantId: string }) => {
  const navigate = useNavigate();
  const [resp, setResp] = useState<PointHistory | undefined>(undefined);
  const [err, setErr] = useState<Error | undefined>(undefined);

  const auth = GetBasicAuth();
  if (auth === null) {
    // This should never happen since the router does not allow access unless
    // logged in.
    return <div>Please log in first.</div>;
  } else {
    useEffect(() => {
      setResp(undefined);
      fetchT<PointHistory>({
        method: "GET",
        url: `${API_URL}/v1/customer/r/${restaurantId}/point_history`,
        auth: auth,
        handleResponse: setResp,
        handleError: setErr,
      });
    }, [restaurantId]);
  }

  let content: React.ReactElement;
  if (err !== undefined) {
    content = (
      <Result
        status="error"
        title="Failed to Get Point History"
        subTitle={ExtractErrorDetail(err).description}
        extra={
          <Button
            type="primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Previous Page
          </Button>
        }
      />
    );
  } else if (resp !== undefined) {
    content = (
      <>
        <Statistic
          title="Current Balance | 點數餘額"
          value={getBalance(resp.credits ?? [])}
        />
        {pointsTabs(resp)}
        <Divider style={{ margin: 0 }}>or</Divider>
        <Button
          type="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to Previous Page
        </Button>
      </>
    );
  } else {
    // Loading
    content = <Spin fullscreen />;
  }

  return content;
};

function creditBalance(c: PointCredit): number {
  let total = c.amount;
  for (const redeem of c.redeems ?? []) {
    total -= redeem.amount;
  }
  return total;
}

function creditExpired(c: PointCredit): boolean {
  if (c.expire_time === undefined) {
    return false;
  }
  return moment(c.expire_time).startOf("day").isBefore(moment().startOf("day"));
}

function getBalance(credits: PointCredit[]): number {
  // Only consider credits that have not expired.
  const unexpired = credits.filter((c) => !creditExpired(c));
  return unexpired.map(creditBalance).reduce((prev, curr) => prev + curr, 0);
}

function pointsTabs(resp: PointHistory): React.ReactElement {
  const unexpired = (resp.credits ?? []).filter((c) => !creditExpired(c));
  const expired = (resp.credits ?? []).filter((c) => creditExpired(c));

  const debits = new Map<string, PointDebit>(
    (resp.debits ?? []).map((d) => [d.id, d]),
  );

  return (
    <Tabs defaultActiveKey="active">
      <TabPane tab="Active | 未過期" key="active">
        <PointsTable credits={unexpired} debits={debits} />
      </TabPane>
      <TabPane tab="Expired | 已過期" key="expired">
        <PointsTable credits={expired} debits={debits} />
      </TabPane>
    </Tabs>
  );
}

// Represents either a credit or debit based on sign of the amount.
type GenericPoint = {
  id: string;
  description: string;
  amount: number;
  create_time: string;
  expire_time?: string;
};

function PointsTable({
  credits,
  debits,
}: {
  credits: PointCredit[];
  debits: Map<string, PointDebit>;
}) {
  // Group debits by ID.
  const groupedDebits: Map<string, GenericPoint> = new Map();
  for (const credit of credits) {
    for (const redeem of credit.redeems ?? []) {
      let p: GenericPoint | undefined = groupedDebits.get(
        redeem.point_debit_id,
      );
      if (p === undefined) {
        const d = debits.get(redeem.point_debit_id);
        p = {
          id: redeem.point_debit_id,
          description: d?.description ?? "",
          amount: -1 * redeem.amount,
          create_time: redeem.create_time,
        };
      } else {
        p.amount += -1 * redeem.amount;
      }
      groupedDebits.set(redeem.point_debit_id, p);
    }
  }

  // Display credits alongside debits.
  const displayData: GenericPoint[] = [...credits];
  groupedDebits.forEach((d: GenericPoint) => {
    displayData.push(d);
  });

  // Sort by most recent first.
  displayData.sort((p1, p2) => {
    const t1 = moment(p1.create_time);
    const t2 = moment(p2.create_time);
    if (t1.isAfter(t2)) {
      return -1;
    } else if (t2.isAfter(t1)) {
      return 1;
    }
    // For equals, prefer to display debits first.
    return p1.amount < p2.amount ? -1 : 1;
  });

  // Limit to 3 columns to fit on mobile screen.
  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Amount",
      key: "amount",
      render: (text: string, record: GenericPoint) => {
        if (record.amount > 0) {
          return <span style={{ color: "green" }}>+{record.amount}</span>;
        }
        return <span style={{ color: "red" }}>{record.amount}</span>;
      },
    },
    {
      title: "Expiration",
      key: "expire_time",
      render: (text: string, record: GenericPoint) => {
        if (record.expire_time === undefined) {
          return undefined;
        }
        return moment(record.expire_time).format("YYYY/MM/DD");
      },
    },
  ];

  return (
    <Table
      rowKey={(r: any) => r.id}
      dataSource={displayData}
      columns={columns}
    />
  );
}
