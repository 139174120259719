import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Divider, Result, Space, Spin, Table, Typography } from "antd";

import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import Page from "../layouts/Page";
import { ItemProgress, OrderProgress } from "../types/api";
import { Shipment } from "../types/model";
import { API_URL, getFetch } from "../utils/Util";
import CustomCard from "../components/CustomCard";

const { Title, Paragraph } = Typography;

export default () => {
  const navigate = useNavigate();
  const { orderID, restaurantSlug } = useParams<{
    orderID: string;
    restaurantSlug: TRestaurantSlug;
  }>();

  const [orderProgress, setOrderProgress] =
    React.useState<OrderProgress | null>(null);
  const [fetchErr, setFetchErr] = React.useState<Error | null>(null);

  React.useEffect(() => {
    if (restaurantSlug) {
      getFetch<OrderProgress>(
        `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/order_progress?order_id=${orderID}`,
        setOrderProgress,
        setFetchErr,
      );
    }
  }, [orderID]);

  if (orderProgress !== null) {
    return (
      <Page>
        <CustomCard>
          <Title
            level={3}
          >{`Tracking Order: ${orderProgress.pos_order_name}`}</Title>
          <Divider style={{ margin: 0 }} />
          <Title level={5}>Items</Title>
          <ItemsTable itemProgress={orderProgress.item_progress} />
          {orderProgress.shipments && orderProgress.shipments.length > 0 && (
            <>
              <Title level={4}>Shipments</Title>
              {orderProgress.shipments.length > 1 ? (
                <Paragraph>{`This order is split into ${orderProgress.shipments.length} shipments.`}</Paragraph>
              ) : null}
              <Space
                size="large"
                direction="vertical"
                style={{ width: "100%" }}
              >
                {orderProgress.shipments.map((s, idx) => {
                  let label = `Track Shipment ${idx + 1}`;
                  if (orderProgress?.shipments?.length === 1) {
                    label = "Track Shipment";
                  }
                  return (
                    <ShipmentLink
                      key={`shipment-${s.id}`}
                      label={label}
                      shipment={s}
                    />
                  );
                })}
              </Space>
            </>
          )}
          <Divider style={{ margin: 0 }}>or</Divider>
          <Button
            type="primary"
            onClick={() => {
              navigate("/");
            }}
            size="large"
          >
            Back to Home
          </Button>
        </CustomCard>
      </Page>
    );
  } else if (fetchErr !== null) {
    return (
      <Page>
        <Result
          status="error"
          title="Oops, something went wrong!"
          subTitle="Please try refreshing the page."
        >
          <Title level={5}>Debug Information</Title>
          <Paragraph
            code
            copyable
          >{`order_id=${orderID},err="${fetchErr.toString()}"`}</Paragraph>
        </Result>
        <Divider style={{ margin: 0 }}>or</Divider>
        <Button
          type="primary"
          onClick={() => {
            navigate("/");
          }}
          size="large"
        >
          Back to Home
        </Button>
      </Page>
    );
  }
  return (
    <Page>
      <Spin size="large" fullscreen />
    </Page>
  );
};

function ItemsTable({ itemProgress }: { itemProgress: ItemProgress[] }) {
  // Group items by attributes.
  type ItemGroup = {
    id: string;
    qty: number;
    name: string;
  };
  const groups = new Map<string, ItemGroup>();

  for (const i of itemProgress) {
    const g = groups.get(i.attributes.id);
    if (g) {
      g.qty += 1;
    } else {
      groups.set(i.attributes.id, {
        id: i.attributes.id,
        qty: 1,
        name: i.attributes.name,
      });
    }
  }

  const groupList: ItemGroup[] = [];
  groups.forEach((v) => groupList.push(v));

  const columns = [
    {
      title: "Qty",
      dataIndex: "qty",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
  ];
  return (
    <Table dataSource={groupList} columns={columns} rowKey={(g: any) => g.id} />
  );
}

function ShipmentLink({
  label,
  shipment,
}: {
  label: string;
  shipment: Shipment;
}) {
  if (shipment.provider === "easyship" && shipment.provider_id !== "") {
    return (
      <a
        href={`https://www.trackmyshipment.co/shipment-tracking/${shipment.provider_id}`}
        target="_blank"
      >
        <Button block size="large" type="primary">
          {label}
        </Button>
      </a>
    );
  }
  return <div />;
}
