import React from "react";
import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import { API_URL, fetchT } from "../utils/Util";
import { GetLargeVolumeDeliveryZonesResponse } from "../types/api";

// A custom hook to fetch delivery zone info for large volume delivery orders.
export const useLargeVolumeDeliveryZoneInfo = ({
  restaurantSlug,
}: {
  restaurantSlug: TRestaurantSlug;
}): GetLargeVolumeDeliveryZonesResponse | null => {
  const [resp, setResp] =
    React.useState<GetLargeVolumeDeliveryZonesResponse | null>(null);

  React.useEffect(() => {
    fetchT<GetLargeVolumeDeliveryZonesResponse>({
      method: "GET",
      url: `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/large_volume/delivery_zones`,
      handleResponse: setResp,
      handleError: (err: Error) => {
        console.log("failed to get delivery zone info", err);
        // Just keep the hook's value as null instead of erroring.
        // This is important because we currently try to fetch delivery zone
        // for all restaurants on menu render, regardless of whether they
        // support delivery orders.
      },
    });
  }, [restaurantSlug]);

  return resp;
};
