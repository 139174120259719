import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoginOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, Input, Result, Typography } from "antd";
import { NotifyError } from "../utils/NotifyError";
import {
  DEFAULT_RESTAURANT_SLUG,
  RESTAURANT_IDS,
  TRestaurantSlug,
} from "../constants/o2";
import Page from "../layouts/Page";
import {
  CreateCustomerPasswordResetTokenRequest,
  CreateCustomerPasswordResetTokenResponse,
} from "../types/api";
import { API_URL, fetchT } from "../utils/Util";
import CustomCard from "../components/CustomCard";
import { useQuery } from "../hooks/useQuery";

const PasswordReset: React.FC = () => {
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { restaurantSlug } = useParams<{ restaurantSlug: TRestaurantSlug }>();

  const onFinish = (req: CreateCustomerPasswordResetTokenRequest) => {
    fetchT<CreateCustomerPasswordResetTokenResponse>({
      method: "POST",
      url: `${API_URL}/v1/public/r/${RESTAURANT_IDS[DEFAULT_RESTAURANT_SLUG]}/password_reset_tokens`,
      body: req,
      handleResponse: () => {
        setLinkSent(true);
      },
      handleError: (err: Error) => {
        NotifyError("Failed to Create Reset Token", err);
      },
    });
  };

  if (linkSent) {
    // TODO: don't hardcode password reset link valid time.
    return (
      <Page>
        <Result
          status="success"
          title="We've sent you a password reset link!"
          subTitle="Please check your email or phone. The link is valid for 30 minutes."
        />
      </Page>
    );
  }

  const formElem = (
    <Form labelAlign="left" layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Email 電⼦郵箱 or Phone 電話"
        name="email_or_phone"
        rules={[
          {
            required: true,
            message: "Please enter your email or phone number!",
          },
          {
            // RFC 3696 with errata limits email to 254 characters
            message: "Email cannot exceed 256 characters.",
            type: "string",
            max: 256,
          },
        ]}
      >
        <Input placeholder="example@email.com" size="large" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" block size="large" htmlType="submit">
          Reset Password / 重設密碼
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Page>
      <CustomCard>
        <Typography.Title level={3}>Reset Your Password</Typography.Title>
        <Alert
          type="info"
          message="Please enter your email or phone number. We'll send a link to reset your password."
          style={{ textAlign: "left" }}
        />
        {formElem}
        <Divider style={{ margin: 0 }}>or</Divider>
        <Button
          block
          size="large"
          onClick={() =>
            navigate(
              {
                pathname: `/${restaurantSlug}/login`,
                search: query.toString(),
              },
              { state: location.state },
            )
          }
          style={{ height: "auto", whiteSpace: "normal" }}
        >
          <LoginOutlined /> Back to Log In / 回到登入
        </Button>
      </CustomCard>
    </Page>
  );
};

export default PasswordReset;
