import React, { useEffect, useState } from "react";

import { Button, Divider, Flex, Result, Select, Spin, Typography } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import "react-responsive-select/dist/react-responsive-select.css";

import { GetBasicAuth } from "../utils/Auth";
import Page from "../layouts/Page";
import PointHistory from "../components/PointHistory";
import { ExtractErrorDetail } from "../utils/NotifyError";
import { FRANCHISE_ID } from "../constants/o2";
import { ActiveRestaurant } from "../types/api";
import { API_URL, fetchT } from "../utils/Util";
import { useNavigate } from "react-router";
import CustomCard from "../components/CustomCard";

const { Title } = Typography;

// Page where user can select from a list of restaurants where they have points
// and see the point history.
function PointHistoryPage() {
  const navigate = useNavigate();
  const [activeRestaurants, setActiveRestaurants] =
    useState<Array<ActiveRestaurant> | null>(null);
  const [err, setErr] = useState<Error | undefined>(undefined);
  const [selectedRestaurant, setSelectedRestaurant] = useState<string | null>(
    null,
  );

  const auth = GetBasicAuth();
  useEffect(() => {
    fetchT<{ restaurants: Array<ActiveRestaurant> }>({
      method: "GET",
      url: `${API_URL}/v1/customer/f/${FRANCHISE_ID}/active_restaurants`,
      // auth should always be set happen since the router does not allow access
      // unless logged in.
      auth: auth ?? "missing-auth",
      handleResponse: (result: { restaurants: Array<ActiveRestaurant> }) => {
        if (result.restaurants.length > 0) {
          setSelectedRestaurant(result.restaurants[0].id);
        }
        setActiveRestaurants(result.restaurants);
      },
      handleError: setErr,
    });
  }, []);

  let content: React.ReactElement;
  if (activeRestaurants === null) {
    content = <Spin size="large" />;
  } else if (err !== undefined) {
    content = (
      <Result
        status="error"
        title="Failed to get restaurant list"
        subTitle={ExtractErrorDetail(err).description}
        extra={
          <Button
            type="primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Previous Page
          </Button>
        }
      />
    );
  } else if (activeRestaurants.length === 0 || selectedRestaurant == null) {
    // We should always set selectedRestaurant to a default if we have any active restaurant.
    content = (
      <Result
        status="warning"
        title="You don't have any points!"
        subTitle="Start earning points today with every purchase on our website!"
        icon={<FrownOutlined />}
        extra={
          <Button
            type="primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Previous Page
          </Button>
        }
      />
    );
  } else {
    content = (
      <Flex vertical gap={8}>
        <Title level={3}>Point History / 點數紀錄</Title>
        <Divider style={{ margin: 0 }} />
        <Title level={5}>Select Restaurant / 選擇餐廳</Title>
        <Select
          size="large"
          options={activeRestaurants.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          value={selectedRestaurant}
          onChange={(value) => {
            if (value != null) {
              setSelectedRestaurant(value);
            }
          }}
        />
        <PointHistory restaurantId={selectedRestaurant} />
      </Flex>
    );
  }

  return (
    <Page>
      <CustomCard>{content}</CustomCard>
    </Page>
  );
}

export default PointHistoryPage;
